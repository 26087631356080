// import React from 'react'
// import CustomInput from '../../../Component/ui/form/input/custom-input'
// import { useDispatch, useSelector } from 'react-redux'
// import { setProductMasterApiJson, setProductMasterData } from '../../../Store/Action/master/product-master/product-master-action'
// import { productMasterSchema } from '../../../Utils/validators/master/product-master/add-product.schema'
// import useValidation from '../../../Hooks/useValidation'
// import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect'
// import { addProduct, searchGeneral, updateProduct } from '../../../Constant/Api/Api'
// import { useCallback } from 'react'
// import CustomSwitch from '../../../Component/ui/switch/custom-switch'
// import CustomButton from '../../../Component/ui/buttons/custom-button';
// import { HitApi } from '../../../Store/Action/Api/ApiAction'
// import useAlertController from '../../../Hooks/use-alert-controller'
// import { useNavigate } from 'react-router-dom'
// import { useEffect } from 'react'
// import { setSearchableSelectSelectedData } from '../../../Store/Action/common/searcheable-select/searcheable-select-action'
// import { ProductMasterVariable as variable } from '../../../Constant/variables/master/product-master/product-master.variable';



// export default function AddProductMaster({ row, closeModal, ApiHit }) {
//   const reduxProduct = useSelector(state => state.ProductMasterReducer)
//   const { errors, validate } = useValidation(productMasterSchema);
//   const reduxUser = useSelector(state => state.UserReducer);
//   const [loading, setLoading] = React.useState(false)
//   const { showCustomAlert } = useAlertController();
//   const navigate = useNavigate();


//   const dispatch = useDispatch()

//   const handleOnChange = useCallback((e, name) => {
//     const { _id, value } = e;


//     const newJson = { [name]: name === 'siteIds' ? _id : value };
//     const updatedJson = { ...reduxProduct?.apiJson, ...newJson };
//     dispatch(setProductMasterApiJson(updatedJson));
//   }, [dispatch, reduxProduct?.apiJson]);



//   useEffect(() => {



//     if (row?._id) {
//       loadDefault(row);
//       var json = [{ name: 'productGroup', value: row?.productGroup, label: row?.productGroup }]
//       dispatch(setSearchableSelectSelectedData(json))
//     }

//   }, [row?._id]);



//   const loadDefault = useCallback((row) => {
//     var json = { ...reduxProduct?.apiJson || {} };
//     console.log('json', json);
//     Object.assign(json, ...Object.keys(variable).map(key => ({ [variable[key]]: row[key] })));
//     Object.assign(json, { siteIds: row?.siteIds?._id })
//     dispatch(setProductMasterApiJson(json));
//   }, [dispatch, reduxProduct?.apiJson]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     var json = reduxProduct?.apiJson;
//     const validationErrors = validate(json);
//     console.log("validationErrors", validationErrors)

//     if (Object.keys(validationErrors).length === 0) {
//       setLoading(true);
//       const apiCall = row?._id ? updateProduct : addProduct;
//       const updatedJson = { ...json, _id: row?._id, status: json?.status || 'Active' };

//       HitApi(updatedJson, apiCall).then((result) => {
//         setLoading(false);
//         if (result?.status === 200 || result?.status === 201) {
//           if (ApiHit) { ApiHit() }

//           showCustomAlert({
//             type: 'success',
//             title: 'Success!',
//             message: result?.message
//           });
//           navigate('/master/product');
//           handleClose()
//         } else if (result?.status === 409) {
//           showCustomAlert({
//             type: 'error',
//             title: 'Error',
//             message: result?.error?.message
//           });
//         } else {
//           alert(result.message);
//         }
//       });
//     }
//   };


//   const handleClose = () => {

//     dispatch(setProductMasterData(null))
//     dispatch(setProductMasterApiJson({}))
//     if (row?._id) {
//       closeModal()
//     }
//   }



//   return (
//     <div className='p-10 rounded-xl bg-white mt-10'>
//       <form >
//         <div className="space-y-5 lg:space-y-6">
//           <div className='grid grid-cols-4 gap-4 '>
//             <CustomInput maxLength={15} name="productName" label="Product Name" value={reduxProduct?.apiJson?.productName} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
//             <CustomInput maxLength={15} name="productCode" label="Product Code" value={reduxProduct?.apiJson?.productCode} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
//             <CustomInput maxLength={50} name="productDescription" label="Product Description" value={reduxProduct?.apiJson?.productDescription} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
//             <SearchableSelect searchable validate={validate} name="productGroup" label="Product Group" api={searchGeneral} dynamicSearch={{ 'usedBy': 'productGroup' }} getFieldName={'value'} value={reduxUser?.apiJson?.roleName} error={errors} reduxState={reduxUser?.apiJson} setAction={setProductMasterApiJson} onChange={(e) => handleOnChange(e, 'productGroup')} />
//             <CustomInput maxLength={6} type={'number'} name="height" label="Height" value={reduxProduct?.apiJson?.height} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
//             <CustomInput maxLength={6} type={'number'} name="width" label="Width" value={reduxProduct?.apiJson?.width} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
//             <CustomInput maxLength={6} type={'number'} name="length" label="Length" value={reduxProduct?.apiJson?.length} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
//             <CustomInput maxLength={6} type={'number'} name="packedWeight" label="Packed Weight" value={reduxProduct?.apiJson?.packedWeight} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
//             <CustomInput maxLength={6} type={'number'} name="weight" label="Weight" value={reduxProduct?.apiJson?.weight} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
//             <CustomInput maxLength={10} type={'number'} name="buyingCost" label="Buying Cost" value={reduxProduct?.apiJson?.buyingCost} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
//             <CustomInput maxLength={10} type={'number'} name="sellingCost" label="Selling Cost" value={reduxProduct?.apiJson?.sellingCost} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
//             <CustomInput maxLength={15} name="grade" label="Grade" value={reduxProduct?.apiJson?.grade} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
//             <CustomSwitch name="captureBatchNo" label={'Capture Batch No'} value={reduxProduct?.apiJson?.captureBatchNo} reduxState={reduxProduct?.apiJson} errors={errors} setAction={setProductMasterApiJson} />
//             <CustomSwitch name="captureLotNo" label={'Capture Lot No'} value={reduxProduct?.apiJson?.captureLotNo} reduxState={reduxProduct?.apiJson} errors={errors} setAction={setProductMasterApiJson} />
//           </div>
//           <div className='flex gap-3 justify-end'>
//             <CustomButton text={'Back'} variant='flat' onClick={() => { window.location.pathname = 'master/product' }} />
//             <CustomButton type={'submit'} text={row?._id ? 'Update' : 'Submit'} loading={loading} onClick={handleSubmit} />
//           </div>
//         </div>

//       </form>
//     </div>
//   )
// }



import React from 'react'
import CustomInput from '../../../Component/ui/form/input/custom-input'
import { useDispatch, useSelector } from 'react-redux'
import { setProductMasterApiJson, setProductMasterData } from '../../../Store/Action/master/product-master/product-master-action'
import { productMasterSchema } from '../../../Utils/validators/master/product-master/add-product.schema'
import useValidation from '../../../Hooks/useValidation'
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect'
import { addProduct, searchGeneral, searchProduct, updateProduct } from '../../../Constant/Api/Api'
import { useCallback } from 'react'
import CustomSwitch from '../../../Component/ui/switch/custom-switch'
import CustomButton from '../../../Component/ui/buttons/custom-button';
import { HitApi } from '../../../Store/Action/Api/ApiAction'
import useAlertController from '../../../Hooks/use-alert-controller'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { setSearchableSelectSelectedData } from '../../../Store/Action/common/searcheable-select/searcheable-select-action'
import { ProductMasterVariable as variable } from '../../../Constant/variables/master/product-master/product-master.variable';



export default function AddProductMaster({ row, closeModal, ApiHit }) {
  const reduxProduct = useSelector(state => state.ProductMasterReducer)
  const { errors, validate } = useValidation(productMasterSchema);
  const reduxUser = useSelector(state => state.UserReducer);
  const [loading, setLoading] = React.useState(false)
  const { showCustomAlert } = useAlertController();
  const navigate = useNavigate();

  const url = window.location.pathname
  const urlId = url.split('/')[4]
  console.log("urlID", urlId)



  const dispatch = useDispatch()

  const handleOnChange = useCallback((e, name) => {
    const { _id, value } = e;


    const newJson = { [name]: name === 'siteIds' ? _id : value };
    const updatedJson = { ...reduxProduct?.apiJson, ...newJson };
    dispatch(setProductMasterApiJson(updatedJson));
  }, [dispatch, reduxProduct?.apiJson]);



  useEffect(() => {



    if (urlId) {
      loadDefault(urlId);
      var json = [{ name: 'productGroup', value: row?.productGroup, label: row?.productGroup }]
      dispatch(setSearchableSelectSelectedData(json))
    }

  }, [row?._id]);

  const loadDefault = useCallback((id) => {
    var json = {
        page: 1,
        limit: 1,
        search: {
            _id: id

        }
    }

    HitApi(json, searchProduct).then((result) => {

        var oldRedux = reduxProduct?.apiJson
        
        if (result?.content[0]) {
            var json = [
                { name: 'productGroup', value: result?.content[0]?.productGroup, label: result?.content[0]?.productGroup },
          
            ]
            dispatch(setSearchableSelectSelectedData(json))
        }

        Object.assign(result?.content[0], {siteIds : result?.content[0]?.siteIds?._id})
        var newRedux = { ...oldRedux, ...result?.content[0] }
        dispatch(setProductMasterApiJson(newRedux));   
    })
})



  const handleSubmit = (e) => {
    e.preventDefault();
    var json = reduxProduct?.apiJson;
    const validationErrors = validate(json);
    console.log("validationErrors", validationErrors)

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const apiCall = urlId? updateProduct : addProduct;
      const updatedJson = { ...json, _id: urlId, status: json?.status || 'Active' };

      HitApi(updatedJson, apiCall).then((result) => {
        setLoading(false);
        if (result?.status === 200 || result?.status === 201) {
          if (ApiHit) { ApiHit() }

          showCustomAlert({
            type: 'success',
            title: 'Success!',
            message: result?.message
          });
          navigate('/master/product');
          handleClose()
        } else if (result?.status === 409) {
          showCustomAlert({
            type: 'error',
            title: 'Error',
            message: result?.error?.message
          });
        } else {
          alert(result.message);
        }
      });
    }
  };


  const handleClose = () => {

    dispatch(setProductMasterData(null))
    dispatch(setProductMasterApiJson({}))
    dispatch(setSearchableSelectSelectedData(null))
    if (row?._id) {
      closeModal()
    }
  }



  return (
    <div className='p-10 rounded-xl bg-white mt-10'>
      <form >
        <div className="space-y-5 lg:space-y-6">
          <div className='grid grid-cols-4 gap-4 '>
            <CustomInput inputType={'text'} maxLength={15} name="productName" label="Product Name" value={reduxProduct?.apiJson?.productName} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
            <CustomInput maxLength={15} name="productCode" label="Product Code" value={reduxProduct?.apiJson?.productCode} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
            <CustomInput maxLength={50} name="productDescription" label="Product Description" value={reduxProduct?.apiJson?.productDescription} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
            <SearchableSelect searchable validate={validate} name="productGroup" label="Product Group" api={searchGeneral} dynamicSearch={{ 'usedBy': 'productGroup' }} getFieldName={'value'} value={reduxUser?.apiJson?.roleName} error={errors} reduxState={reduxUser?.apiJson} setAction={setProductMasterApiJson} onChange={(e) => handleOnChange(e, 'productGroup')} />
            <CustomInput maxLength={6} type={'number'} name="height" label="Height" value={reduxProduct?.apiJson?.height} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
            <CustomInput maxLength={6} type={'number'} name="width" label="Width" value={reduxProduct?.apiJson?.width} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
            <CustomInput maxLength={6} type={'number'} name="length" label="Length" value={reduxProduct?.apiJson?.length} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
            <CustomInput maxLength={6} type={'number'} name="packedWeight" label="Packed Weight" value={reduxProduct?.apiJson?.packedWeight} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
            <CustomInput maxLength={6} type={'number'} name="weight" label="Weight" value={reduxProduct?.apiJson?.weight} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
            <CustomInput maxLength={10} type={'number'} name="buyingCost" label="Buying Cost" value={reduxProduct?.apiJson?.buyingCost} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
            <CustomInput maxLength={10} type={'number'} name="sellingCost" label="Selling Cost" value={reduxProduct?.apiJson?.sellingCost} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
            <CustomInput maxLength={15} name="grade" label="Grade" value={reduxProduct?.apiJson?.grade} error={errors} reduxState={reduxProduct?.apiJson} setAction={setProductMasterApiJson} validate={validate} />
            <CustomSwitch name="captureBatchNo" label={'Capture Batch No'} value={reduxProduct?.apiJson?.captureBatchNo} reduxState={reduxProduct?.apiJson} errors={errors} setAction={setProductMasterApiJson} />
            <CustomSwitch name="captureLotNo" label={'Capture Lot No'} value={reduxProduct?.apiJson?.captureLotNo} reduxState={reduxProduct?.apiJson} errors={errors} setAction={setProductMasterApiJson} />
          </div>
          <div className='flex gap-3 justify-end'>
            <CustomButton text={'Back'} variant='flat' onClick={() => { window.location.pathname = 'master/product' }} />
            <CustomButton type={'submit'} text={urlId ? 'Update' : 'Submit'} loading={loading} onClick={handleSubmit} />
          </div>
        </div>

      </form>
    </div>
  )
}

