import React, { useEffect } from 'react'
import { IoAdd } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActionIcon, Button, Title } from 'rizzui';
import { useModal } from './modal-views/use-modal';
import { pageHeader } from '../config/pageHeader';
import { setSearchableSelectSelectedData } from '../Store/Action/common/searcheable-select/searcheable-select-action';
import { useDispatch } from 'react-redux';
import { getHeadingFromPathname } from '../Utils/Utils';
import { FilterCondition } from '../Constant/filters/Filter';
import { deactiveText } from '../Constant/Common/common-variable';
import { PiTextColumns, PiTrashDuotone } from "react-icons/pi";
import { ToggleColumns } from '../Component/ui/table/table';


export default function PageHeader({ title, href, titleClass, children, screen, customSize, metaTitle, ApiHit, setState, json, setAction, columns, checkedColumns, setCheckedColumns, hideIndex }) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { openModal } = useModal();
  const FilterItem = FilterCondition?.find(Obj => Obj?.screen === screen);

  useEffect(() => {

    console.log('columns = = = = ', columns);

  }, [columns])


  const handleClick = () => {
    if (href) {
      if (setState) {
        dispatch(setState({}))
        dispatch(setSearchableSelectSelectedData([]))
      }
      navigate(href);
    } else {
      openModal({
        view: children,
        customSize: customSize + 'px' || '1020px',
        title: title,
        titleClass: titleClass
      });
    }
  }

  const handleFilterChange = (e, serverKey) => {
    let { label, value, _id } = e
    if (value === 'all') {
      Object.assign(json, { search: {} })
      dispatch(setSearchableSelectSelectedData([]))
    } else {
      Object.assign(json.search, { [serverKey]: value })
    }
    dispatch(setAction(json))
    if (ApiHit) { ApiHit() }
  }

  const removeJson = () => {
    var tj = json
    Object.assign(json, { search: { status: { "$ne": deactiveText } } })
    dispatch(setAction(json))
    dispatch(setSearchableSelectSelectedData([]))
    if (ApiHit) { ApiHit() }
  }
  
  console.log('json lenth', Object.keys(json?.search)?.length);


  return (
    <div className='flex justify-between items-center'>
      <div className='flex space-x-3 items-center'>
        <div><Button size='sm' className='font-bold tracking-wider custom-button' onClick={() => handleClick()}> <IoAdd className='h-4 w-4' /> Create </Button></div>
        <Title as='h5'>{getHeadingFromPathname()}</Title>
      </div>
      <div className='mx-2 my-5'>
        <div className='flex space-x-3'>
          {
            json && FilterItem?.condition?.map((ele, index) => <div key={index}>
              <div><label className='font-semibold text-xs'>{ele?.title}</label></div>
              {typeof ele.render === 'function'
                ? ele.render(
                  (e) => handleFilterChange(e, ele?.serverKey),
                  () => removeJson()
                )
                : ele.render}
            </div>)
          }
          { Object.keys(json?.search || {}).length > 1 || json?.search?.status?.["$ne"] !== deactiveText ? ( <div className='mt-0'> <Button size="sm" onClick={removeJson} className="h-8 bg-gray-200/70" variant="flat"> <PiTrashDuotone className="me-1.5 h-[17px] w-[17px]" /> Clear </Button> </div> ) : null }
          {columns && <ToggleColumns columns={columns} checkedColumns={checkedColumns} setCheckedColumns={setCheckedColumns} hideIndex={hideIndex}/> }
          
        </div>
      </div>
    </div>
  )
}



// {json && Object.keys(json?.search)?.length > 0 && json?.search?.status?.["$ne"] !== deactiveText && <div className='mt-0'><Button size="sm" onClick={() => { removeJson(); }} className="h-8 bg-gray-200/70" variant="flat" > <PiTrashDuotone className="me-1.5 h-[17px] w-[17px]" /> Clear </Button></div>}