export const StatusOptions = [
    { _id: 0, label: 'Active', value: 'active', status: true, },
    { _id: 1, label: 'In Active', value: 'in-active', status: true, },
]

export const ReportTypeOptions = [
    { _id: 0, label: 'Hourly', value: 'hourly', status: true, },
    { _id: 0, label: 'Daily', value: 'daily', status: true, },
    { _id: 1, label: 'Weekly', value: 'weekly', status: true, },
    { _id: 1, label: 'Monthly', value: 'monthly', status: true, },
]

