import React, { useState } from 'react'
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import {  deleteVehicle } from '../../../Constant/Api/Api';
import { HeaderCell } from '../../../Component/ui/table';
import { Text } from 'rizzui';
import Skeleton from 'react-loading-skeleton';
import TableActions from '../../../Component/ui/table/TableActions';
import { EditScreen } from '../../../shared/edit-screen';
import useAlertController from '../../../Hooks/use-alert-controller';
import AddVehicleMaster from '../../../Form/master/vehicle-master/add-vehcile-master';
import { ScreenName } from '../../../Constant/Screen/Screen';


export const GetVehicleMasterColumns = (openModal, closeModal, ApiHit) => {
  const { showCustomAlert } = useAlertController();
  const [loadingRows, setLoadingRows] = useState({})


  const handleDelete = async (row) => {
    setLoadingRows((prev) => ({ ...prev, [row.index]: true }));

    const json = { _id: row?._id };



    try {
      const result = await HitApi(json, deleteVehicle);
      if (result?.success !== false) {
        showCustomAlert({
          type: 'success',
          title: 'Success!',
          message: 'Vehicle Deleted Successfully',
        });
        if (ApiHit) { ApiHit(); }
      } else {
        showCustomAlert({
          type: 'error',
          title: 'Delete Error',
          message: 'Unable to delete this role. This role is already linked with a user.',
        });
      }
    } catch (err) {

    } finally {
      setLoadingRows((prev) => ({ ...prev, [row.index]: false }));
    }
    
  };

  const renderCell = (value, row, content) => (
    loadingRows[row.index] ? <Skeleton /> : content
  );

    return [
      {
        title: <HeaderCell title="#" />,
        dataIndex: 'index',
        key: 'index',
        width: 10,
        render: (value, row, index) => renderCell(value, row, <Text>{index + 1 || '---'}</Text>),
      },
      {
        title: <HeaderCell title="Vehicle Maker" className="font-extrabold" />,
        dataIndex: 'vehicleMaker',
        key: 'vehicleMaker',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Vehicle Number" className="font-extrabold" />,
        dataIndex: 'vehicleNumber',
        key: 'vehicleNumber',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Vehicle Model" className="font-extrabold" />,
        dataIndex: 'vehicleModel',
        key: 'vehicleModel',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Vehicle Year" className="font-extrabold" />,
        dataIndex: 'vehicleYear',
        key: 'vehicleYear',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Vehicle Engine" className="font-extrabold" />,
        dataIndex: 'vehicleEngine',
        key: 'vehicleEngine',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Vehicle Type" className="font-extrabold" />,
        dataIndex: 'vehicleType',
        key: 'vehicleType',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Tag Ids" className={'font-extrabold'} />,
        dataIndex: 'tagIds',
        key: 'tagIds',
        width: 100,
        render: (value) => (
          <Text className="font-medium text-gray-700">
            {value}
          </Text>
        ),
      },
      {
        title: <HeaderCell title="Status" className="font-extrabold" />,
        dataIndex: 'status',
        key: 'status',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
      {
        title: <HeaderCell title="Actions" className="font-extrabold" />,
        dataIndex: 'action',
        key: 'action',
        width: 300,
        render: (_, row) => renderCell(null, row, (
          <TableActions
            screen={ScreenName.vehicleMaster}
            row={row}
            onEdit={(rowData) => EditScreen(openModal, closeModal, rowData, 'Edit Vehicle Master', AddVehicleMaster, 800, ApiHit)}
            onDelete={(rowData) => handleDelete(rowData)}
            checkKeys={[]}
          />
        )),
      },
    ];
  
}
