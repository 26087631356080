import React, { useEffect, useState } from "react";
import { searchTag } from "../../Constant/Api/Api";
import { HitApi } from "../../Store/Action/Api/ApiAction";
import { INVENTORY } from "../../Constant/Common/common-variable";
import { Colors } from "../../Constant/Colors/Color";
import { Loader } from "rizzui";

const DashboardCard = ({ title, isCardSelected, inventoryQuery, cardType, api, onClickCard, render, setRender }) => {

    const [count, setCount] = useState(null)
    const [loading, setLoading] = useState(null)

    useEffect(() => {
        if (count === null) {
            fetchInventory()
        }
        if (render) {
            setCount(null)
            setRender(false)
        }
    }, [count, render])

    const fetchInventory = async () => {
        var json = inventoryQuery;
        if (api) {
            setLoading(true)
            HitApi(json, api).then(res => {
                setCount(res?.totalElements || 0)
                setLoading(false)
            });
        } else {
            setCount(0)
        }
    }

    return (
        <div style={{ border: isCardSelected ? `1px solid ${Colors.LOGINRED}` : '' }} className="cursor-pointer bg-white border border-muted p-5 lg:p-6 rounded-lg w-full max-w-full justify-between" onClick={onClickCard}>
            <div className="flex items-center justify-between ">
                <div className="flex items-center">
                    <div className="">
                        <p className="rizzui-text-p font-normal mb-0.5 text-gray-500">{title}</p>
                        <p className="flex gap-2 rizzui-text-p font-lexend text-lg font-semibold text-gray-900 2xl:xl:text-xl dark:text-gray-700 3xl:text-[22px]">
                            <span className="flex gap-2">{!loading?count:<Loader/>}</span>
                            <span className="text-gray-500 text-base mt-0.5">
                                {cardType}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <p className="rizzui-text-p font-normal mt-3 flex items-center leading-none text-gray-500">
                <span className="rizzui-text-span me-2 inline-flex items-center font-medium text-green">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="me-1 h-4 w-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"></path>
                    </svg>
                    +32.40%
                </span>
                last month
            </p>
        </div>
    )
}
export default DashboardCard;
