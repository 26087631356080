import React, { useMemo, useState } from 'react'
import SearchableSelect from '../../Component/ui/form/select/SearchableSelect'
import { searchBuilding, searchOrder, searchTag, searchTagHistory } from '../../Constant/Api/Api'
import { useDispatch, useSelector } from 'react-redux'
import { HitApi } from '../../Store/Action/Api/ApiAction'
import { getAuthenticatedUser } from '../../Storage/Storage'
import { setDashboardData, setSelectedBuildingData, } from '../../Store/Action/dashbaord/DashboardAction'
import DashboardCard from './dashBoardCard'
import { ACTIVE, ACTIVEINVENTORY, DISPATCHED, INACTIVE, INACTIVEINVENTORY, INBOUNDORDER, INCOMINGORDER, INVENTORY, ORDER, OUTBOUNDORDER, READER, globalSearch, inventoryQuery } from '../../Constant/Common/common-variable'
import ControlledTable from "../../Component/ui/table/custom-table";
import { useColumn } from '../../Hooks/use-column'
import { TableClass } from '../../Constant/Classes/Classes'
import { GetDashboardTableColumns } from './dashboardTableColumns'
import { useModal } from '../../shared/modal-views/use-modal'
import ChartView from './chartView'
import OrderTable from '../../Component/order-table/order-table'

export default function Dashboard() {

  const dashbaordRedux = useSelector(state => state.DashboardReducer)
  const dispatch = useDispatch()
  const { openModal, closeModal } = useModal();
  const columns = useMemo(() => GetDashboardTableColumns(openModal, closeModal))
  const { visibleColumns } = useColumn(columns);
  const [loading, setLoading] = useState(false);
  const [selectedCardName, setSelectedCardName] = useState(null);
  const [render, setRender] = useState(false);

  const onClickInventory = (type) => {
    nullChartData(type)
    setSelectedCardName(type)
    if (type === ACTIVEINVENTORY) {
      var json = inventoryQuery(dashbaordRedux?.selectedBuilding?._id, ACTIVE, INVENTORY)
      setLoading(true)
      HitApi(json, searchTag).then(res => {
        console.log('res', res);
        setLoading(false)
        var oldData = dashbaordRedux?.doc
        oldData.table = res?.content
        dispatch(setDashboardData(oldData))
      })
    } else if (type === INACTIVEINVENTORY) {
      var json = inventoryQuery(dashbaordRedux?.selectedBuilding?._id, INACTIVE, INVENTORY)
      setLoading(true)
      HitApi(json, searchTag).then(res => {
        console.log('res', res);
        setLoading(false)
        var oldData = dashbaordRedux?.doc
        oldData.table = res?.content
        dispatch(setDashboardData(oldData))
      })
    }
  }

  const onChnageBuilding = (data) => {
    dispatch(setSelectedBuildingData(data))
    setRender(true)
  }

  const nullChartData = (type) => {
    var oldData = dashbaordRedux?.doc
    oldData.chart = { type: 'Weekly', data: null }
    oldData.table = { type: type, data: null }
    dispatch(setDashboardData(oldData))
  }

  return (
    <div className='p-5'>
      <div className='grid grid-cols-12 gap-5'>
        <div className='col-span-2'>
          <p>Select Building</p>
          <SearchableSelect name='Building' onChange={(e) => onChnageBuilding(e?.ele)} api={searchBuilding} getFieldName={'buildingName'} dynamicSearch={{ userIds: { $in: [getAuthenticatedUser()?.userId] } }} />
        </div>
      </div>

      {
        dashbaordRedux?.selectedBuilding !== null &&
        <div className='mt-5 grid grid-cols-6 gap-5'>
          <DashboardCard render={render} setRender={setRender} isCardSelected={selectedCardName === ACTIVEINVENTORY} onClickCard={() => onClickInventory(ACTIVEINVENTORY)} cardType={INVENTORY} api={searchTag} inventoryQuery={inventoryQuery(dashbaordRedux?.selectedBuilding?._id, ACTIVE, INVENTORY)} title={'Active Inventory'} />
          <DashboardCard render={render} setRender={setRender} isCardSelected={selectedCardName === INACTIVEINVENTORY} onClickCard={() => onClickInventory(INACTIVEINVENTORY)} cardType={INVENTORY} api={searchTag} inventoryQuery={inventoryQuery(dashbaordRedux?.selectedBuilding?._id, INACTIVE, INVENTORY)} title={'Inactive Inventory'} />
          <DashboardCard render={render} setRender={setRender} cardType={READER} title={'Total Fixed Readers'} />
          <DashboardCard render={render} setRender={setRender} onClickCard={() => onClickInventory(INBOUNDORDER)} inventoryQuery={globalSearch(1, 10, { dispatchTo: dashbaordRedux?.selectedBuilding?._id, orderStatus: DISPATCHED })} api={searchOrder} cardType={ORDER} title={'Inbound Orders'} />
          <DashboardCard render={render} setRender={setRender} onClickCard={() => onClickInventory(OUTBOUNDORDER)} inventoryQuery={globalSearch(1, 10, { dispatchFrom: dashbaordRedux?.selectedBuilding?._id, dispatchTo: { "$not": { "$regex": dashbaordRedux?.selectedBuilding?._id } } })} api={searchOrder} cardType={ORDER} title={'Outbound Orders'} />
          <DashboardCard render={render} setRender={setRender} onClickCard={() => onClickInventory(INCOMINGORDER)} inventoryQuery={globalSearch(1, 10, { dispatchTo: dashbaordRedux?.selectedBuilding?._id, orderStatus: DISPATCHED })} api={searchOrder} cardType={ORDER} title={'Incomming Orders'} />
        </div>
      }

      {
        dashbaordRedux?.doc?.table !== null &&
          selectedCardName === ACTIVEINVENTORY ?
          <div className='grid grid-cols-2 gap-5 mt-5'>
            <div>
              <ControlledTable
                variant="modern"
                isLoading={loading}
                data={dashbaordRedux?.doc?.table}
                columns={visibleColumns}
                className={TableClass}
              />
            </div>
            <div>
              <ChartView isSingleBarChart={ACTIVE} api={searchTagHistory} apiJson={globalSearch(1, 100000, { currentLocation: dashbaordRedux?.selectedBuilding?._id, tagType: "INVENTORY", opreationStatus: ACTIVE })} />
            </div>
          </div>
          :
          selectedCardName === INACTIVEINVENTORY ?
            <div className='grid grid-cols-2 gap-5 mt-5'>
              <div>
                <ControlledTable
                  variant="modern"
                  isLoading={loading}
                  data={dashbaordRedux?.doc?.table}
                  columns={visibleColumns}
                  className={TableClass}
                />
              </div>
              <div>
                <ChartView isSingleBarChart={INACTIVE} api={searchTagHistory} apiJson={globalSearch(1, 100000, { currentLocation: dashbaordRedux?.selectedBuilding?._id, tagType: "INVENTORY", opreationStatus: INACTIVE })} />
              </div>
            </div>
            :
            selectedCardName === INBOUNDORDER ?
              <div className='grid grid-cols-2 gap-5 mt-5'>
                <div>
                  <OrderTable type={true} buildingId={dashbaordRedux?.selectedBuilding?._id} />
                </div>
                <div>
                  {/* <ChartView api={searchTagHistory} apiJson={globalSearch(1,100000,{currentLocation: dashbaordRedux?.selectedBuilding?._id,tagType: "INVENTORY",opreationStatus:INACTIVE})}/> */}
                </div>
              </div>
              :
              selectedCardName === OUTBOUNDORDER ?
                <div className='grid grid-cols-2 gap-5 mt-5'>
                  <div>
                    <OrderTable type={false} buildingId={dashbaordRedux?.selectedBuilding?._id} />
                  </div>
                  <div>
                    {/* <ChartView api={searchTagHistory} apiJson={globalSearch(1,100000,{currentLocation: dashbaordRedux?.selectedBuilding?._id,tagType: "INVENTORY",opreationStatus:INACTIVE})}/> */}
                  </div>
                </div>
                :
                ''
      }
    </div>
  )
}