import React from 'react'
import CarbonSidebar from './carbon-sidebar'
import CustomRoutes from './Routes'
import Header from './carbon-header'

export default function Layout({ children }) {
  return (
    <main className="flex min-h-screen flex-grow">
      <CarbonSidebar className="fixed flex-col justify-between dark:bg-gray-50 block " />
      <div className="flex w-full flex-col ms-[100px] ">
        <Header />
        <div className="flex flex-grow flex-col px-4 pb-6 pt-2 md:px-5 lg:px-6 lg:pb-8 3xl:px-8 3xl:pt-4 4xl:px-10 4xl:pb-9">
          <CustomRoutes />
        </div>
      </div>
    </main>
  )
}
