import React, { useEffect, useState } from 'react'
import SearchableSelect from '../../../../Component/ui/form/select/SearchableSelect'
import CustomInput from '../../../../Component/ui/form/input/custom-input'
import { useDispatch, useSelector } from 'react-redux'
import CustomButton from '../../../../Component/ui/buttons/custom-button'
import useDynamicLoading from '../../../../Hooks/use-dynamic-loading'
import { useModal } from '../../../../shared/modal-views/use-modal'
import { searchLocation, searchProduct, searchVehicle, searchZone } from '../../../../Constant/Api/Api'
import { setOrderApiJson } from '../../../../Store/Action/order/order-action'
import { HitApi } from '../../../../Store/Action/Api/ApiAction'
import { CompileDynamicDataLoad } from '../../promiss/order-promiss'
import useValidation from '../../../../Hooks/useValidation'
import { validationSchema } from '../../../../Utils/validators/validationSchema'
import { setSearchableSelectData, setSearchableSelectSelectedData } from '../../../../Store/Action/common/searcheable-select/searcheable-select-action'
import SearchableMultiSelect from '../../../../Component/ui/form/select/searchable-multi-select'

export const addProductSchema = {
  vehicleIds: validationSchema?.array()?.required('Vehicle Field is Required'),
};

export const KeysToDelete = [
  { removeCase: 'add', removeFromSelect: ['productId', 'zoneIds', 'locationIds'], removeFromRedux: [] },
]

export default function SearchAndAddVehicle({ index, mode, zoneId }) {
  const dispatch = useDispatch()
  const reduxOrder = useSelector(state => state.OrderReducer)
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const { loadingState, setDynamicLoading } = useDynamicLoading()
  const { openModal, closeModal } = useModal();
  const { errors, validate } = useValidation(addProductSchema);


  useEffect(() => {
    console.log('mode', mode, zoneId);
    if (mode === 'edit' && zoneId) {
      // loadDynamicLocation(zoneId)
    }

  }, [])

  const handleSubmit = () => {
    var json = reduxOrder?.apiJson
    const validationErrors = validate(json);
    console.log(json);
    if (Object.keys(validationErrors).length === 0) {
      closeModal()
    }
  }

  const handleClose = () => {
    closeModal();
  };

  const handleClear = (name) => {
    var json = reduxOrder?.apiJson?.['productIds'][index]
    var findCase = KeysToDelete?.find(Obj => Obj.removeCase === name)
    var filterItems = reduxSelect?.selected?.filter(Obj => !findCase?.removeFromSelect?.includes(Obj?.name))
    dispatch(setSearchableSelectSelectedData(filterItems))
    console.log('findCase', findCase);

    findCase?.removeFromRedux?.map((ele) => delete json[ele])
  }

  return (
    <div className='p-10'>
      <div className="space-y-5 lg:space-y-6">
        {/* <SearchableSelect selectionType={'productId'} name={'productId'} label={'Select Product'} validate={validate} error={errors} api={searchProduct} dynamicSearch={dynamicSearch} getFieldName={'productName'} onChange={handleProductChange} onClear={() => handleClear('productId')} disabled={mode === 'edit'} /> */}
        <SearchableMultiSelect name={'vehicleIds'} api={searchVehicle} getFieldName={'vehicleNumber'} callBackJson={{ vehicleNumber: 'label', vehicleId: 'value', label: 'label', value: 'value', vehicleId: 'ele' }} dynamicSearch={{ tagIds: { "$exists": true } }} reduxState={reduxOrder?.apiJson} />

        <div className='flex gap-3 justify-end'>
          <CustomButton text={'Cancel'} variant='flat' className={''} onClick={handleClose} />
          <CustomButton type={'submit'} className={''} loading={loadingState?.doc?.addProduct} text={'Add'} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  )
}
