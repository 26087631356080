import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useValidation from '../../../Hooks/useValidation';
import CustomButton from '../../../Component/ui/buttons/custom-button';
import CustomInput from '../../../Component/ui/form/input/custom-input';
import { addBuilding, searchGeneral, searchSite, updateBuilding, } from '../../../Constant/Api/Api';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { setBuildingMasterApiJson } from '../../../Store/Action/master/building-master/building-master-action';
import { builingMasterSchema } from '../../../Utils/validators/master/building-master/building-master.schema';
import { buildingMasterVariable as variable } from '../../../Constant/variables/master/building-master/building-master.variable';
import useDeleteKeys from '../../../Hooks/use-delete-keys';
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect';
import { setSearchableSelectSelectedData } from '../../../Store/Action/common/searcheable-select/searcheable-select-action';
import useDynamicLoading from '../../../Hooks/use-dynamic-loading';
import { _id } from '../../../Constant/Common/common-variable';
import useCustomAlertController from '../../../Hooks/use-custom-alert';


export default function AddSiteMaster({ row, closeModal, ApiHit }) {
    var dispatch = useDispatch()
    const reduxBuilding = useSelector(state => state.BuildingMasterReducer)
    const reduxSelect = useSelector(state => state.SearchableSelectReducer)
    const { errors, validate } = useValidation(builingMasterSchema);
    const deleteKeys = useDeleteKeys();
    const { showCustomAlert } = useCustomAlertController();
    const { loadingState, setDynamicLoading } = useDynamicLoading();
    let selectionType = 'add-building'

    useEffect(() => {
        if (row?._id) {
            loadDefault(row)
        }

    }, [])

    const loadDefault = (row) => {
        var json = reduxBuilding?.apiJson
        var selected = reduxSelect?.selected
        Object.assign(json, ...Object.keys(variable).map(key => ({ [variable[key]]: row[key] })));
        dispatch(setSearchableSelectSelectedData([...selected,
        { label: row?.['unitName'], label: row?.['unitName'], name: 'unitName', selectionType: selectionType },
        { label: row?.['status'], label: row?.['status'], name: 'status', selectionType: selectionType }
        ]))
        dispatch(setBuildingMasterApiJson(json))
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        var json = reduxBuilding?.apiJson
        const validationErrors = validate(json);
        if (Object.keys(validationErrors).length === 0) {
            setDynamicLoading({ 'addBuilding': true })
            if (row?._id) {
                Object.assign(json, { _id: row?._id })
                HitApi(json, updateBuilding).then((result) => {
                    showCustomAlert(result)
                    if (ApiHit) { ApiHit() }
                    handleClose()
                    setDynamicLoading({ 'addBuilding': false })
                })
            } else {
                Object.assign(json, { status: json?.status || 'active' })
                HitApi(json, addBuilding).then((result) => {
                    showCustomAlert(result)
                    if (ApiHit) { ApiHit() }
                    handleClose()
                    setDynamicLoading({ 'addBuilding': false })
                })
            }

        } else {
            setDynamicLoading({ 'addBuilding': false })
        }

    };

    const handleCustomChange = (e) => {
        var json = reduxBuilding?.apiJson
        const { _id, value } = e
        Object.assign(json, { unitId: _id, unitName: value })
        dispatch(setBuildingMasterApiJson(json))
    }

    const handleStatusChange = (e) => {
        var json = reduxBuilding?.apiJson
        const { _id, value } = e
        Object.assign(json, { status: value })
        dispatch(setBuildingMasterApiJson(json))
    }

    const handleClose = () => {
        closeModal();
        dispatch(setBuildingMasterApiJson({}))
    }

    const onClear = () => {
        var json = reduxBuilding?.apiJson
        delete json?.unitId
        delete json?.unitName
    }

    return (
        <div className='p-10'>
            <form onSubmit={handleSubmit}>
                <div className="">
                    <div className='grid grid-cols-2 gap-4'>
                        <CustomInput name="buildingName" inputType={'text'} label="Building Name" validate={validate} value={reduxBuilding?.apiJson?.buildingName} error={errors} reduxState={reduxBuilding?.apiJson} setAction={setBuildingMasterApiJson} />
                        <CustomInput name="buildingNo" inputType={'number'} label="Building No" validate={validate} value={reduxBuilding?.apiJson?.buildingNo} error={errors} reduxState={reduxBuilding?.apiJson} setAction={setBuildingMasterApiJson} />
                    </div>
                    <div className='grid grid-cols-2 gap-4'>
                        <SearchableSelect name="unitName" label="Unit" searchable selectionType={selectionType} api={searchSite} getFieldName={'siteName'} value={reduxBuilding?.apiJson?.unitName} error={errors} reduxState={reduxBuilding?.apiJson} setAction={setBuildingMasterApiJson} onChange={handleCustomChange} onClear={onClear} />
                        {row?.[_id] && <SearchableSelect name="status" label="Status" selectionType={selectionType} api={searchGeneral} dynamicSearch={{ 'usedBy': 'status' }} getFieldName={'value'} validate={validate} error={errors} reduxState={reduxBuilding?.apiJson} setAction={setBuildingMasterApiJson} onChange={handleStatusChange} />}
                    </div>

                    <div className='flex gap-3 justify-end mt-10'>
                        <CustomButton text={'Cancel'} variant='flat' className={''} onClick={() => handleClose()} />
                        <CustomButton loading={loadingState?.doc?.addBuilding} type={'submit'} className={''} text={row?._id ? 'Update' : 'Submit'} />
                    </div>
                </div>
            </form>

        </div>
    )
}
