import { useState, useEffect } from 'react';
import moment from 'moment';

const useCompileChartData = (dataArray, type, date) => {
  const [compiledData, setCompiledData] = useState([]);

  var selectedDate = moment(date).format('YYYY-MM-DD');

  useEffect(() => {
    const dataMap = {};
    // Define month names (Jan, Feb, Mar, etc.) in correct order
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    if (type === 'hourly') {
      // Populate hours from 01 to 24
      for (let i = 1; i <= 24; i++) {
        const hour = i.toString().padStart(2, '0'); // Ensure 01, 02, ..., 24
        dataMap[hour] = [];
      }

      dataArray.forEach((element) => {
        const updatedAt = moment(element.updatedAt);
        const updatedDate = updatedAt.format('YYYY-MM-DD');

        if (updatedDate === selectedDate) {
          const hour = updatedAt.format('HH'); // Get the hour
          dataMap[hour].push(element);
        }
      });

      // Transform dataMap to the required format
      const transformedData = Object.keys(dataMap).map((key) => ({
        label: `${key}:00`, // Label as the hour
        data: dataMap[key],
        length: dataMap[key]?.length
      }));

      setCompiledData(transformedData);

    } else if (type === 'daily') {
      const userDate = moment(selectedDate, 'YYYY-MM-DD');
      const currentYear = userDate.year();
      const currentMonth = userDate.month() + 1;

      const startOfMonth = moment(`${currentYear}-${currentMonth}-01`, 'YYYY-MM-DD');
      const daysInMonth = startOfMonth.daysInMonth();

      for (let i = 1; i <= daysInMonth; i++) {
        const day = moment(`${currentYear}-${currentMonth}-${i}`, 'YYYY-MM-DD').format('DD-MM-YYYY');
        dataMap[day] = [];
      }

      dataArray.forEach((element) => {
        const updatedAt = moment(element.updatedAt).format('YYYY-MM-DD');
        const elementMonth = moment(element.updatedAt).month();
        const elementYear = moment(element.updatedAt).year();

        if (elementMonth === userDate.month() && elementYear === userDate.year()) {
          const day = moment(element.updatedAt).format('DD-MM-YYYY');
          dataMap[day].push(element);
        }
      });

      const transformedData = Object.keys(dataMap).map((key) => ({
        label: key, // Label as the day
        data: dataMap[key],
        length: dataMap[key]?.length
      }));
      

      setCompiledData(transformedData);

    } else if (type === 'weekly') {
      const userDate = moment(selectedDate, 'YYYY-MM-DD');
      const currentYear = userDate.year();
      const currentMonth = userDate.month() + 1;


      console.log('currentYear', currentYear, 'currentMonth', currentMonth);

      const startOfMonth = moment(`${currentYear}-${currentMonth}-01`, 'YYYY-MM-DD');
      const endOfMonth = startOfMonth.clone().endOf('month');

      let weekCount = 1;
      let currentWeekStart = startOfMonth.clone().startOf('week');
      let currentWeekEnd = currentWeekStart.clone().endOf('week');

      while (currentWeekStart.isBefore(endOfMonth)) {
        const weekKey = `week${weekCount}`;
        dataMap[weekKey] = [];
        weekCount++;

        currentWeekStart = currentWeekEnd.add(1, 'day').startOf('week');
        currentWeekEnd = currentWeekStart.clone().endOf('week');
      }

      dataArray.forEach((element) => {
        const updatedAt = moment(element.updatedAt);
        const elementMonth = updatedAt.month();
        const elementYear = updatedAt.year();

        if (elementMonth === userDate.month() && elementYear === userDate.year()) {
          const weekOfMonth = Math.ceil(updatedAt.date() / 7);
          const weekKey = `week${weekOfMonth}`;
          dataMap[weekKey].push(element);
        }
      });

      const transformedData = Object.keys(dataMap).map((key) => ({
        label: key, // Label as the week
        data: dataMap[key],
        length: dataMap[key]?.length
      }));

      setCompiledData(transformedData);

    } else if (type === 'monthly') {
      const userDate = moment(selectedDate, 'YYYY-MM-DD');
      const selectedYear = userDate.year();

      monthNames.forEach((month, index) => {
        const monthKey = `${month}-${selectedYear}`;
        dataMap[monthKey] = [];
      });

      dataArray.forEach((element) => {
        const updatedAt = moment(element.updatedAt);
        const elementMonth = updatedAt.month();
        const elementYear = updatedAt.year();

        if (elementYear === selectedYear) {
          const monthKey = `${monthNames[elementMonth]}-${elementYear}`;
          dataMap[monthKey].push(element);
        }
      });

      const transformedData = Object.keys(dataMap).map((key) => ({
        label: key, // Label as the month
        data: dataMap[key],
        length: dataMap[key]?.length
      }));

      setCompiledData(transformedData);
    }
  }, [dataArray, type, selectedDate]);

  return compiledData;
};

export default useCompileChartData;
