import { atom, useAtomValue, useSetAtom } from 'jotai';

const modalAtom = atom({ isOpen: false, view: null, customSize: '320px', titleClass : '', useConfirmBox : '' });

export function useModal() {
  const state = useAtomValue(modalAtom);
  const setState = useSetAtom(modalAtom);

  const openModal = ({ view, customSize, title, titleClass, useConfirmBox }) => {
    setState({ ...state, isOpen: true, view, customSize, title, titleClass, useConfirmBox });
  };

  const closeModal = () => {
    setState({ ...state, isOpen: false });
  };

  // const closeModal = () => {
  //   // Add a slight delay before closing the modal to give time for the toaster to show
  //   setTimeout(() => {
  //     setState({ ...state, isOpen: false });
  //   }, 300); // 300ms delay should be sufficient
  // };
  

  return { ...state, openModal, closeModal };
}
