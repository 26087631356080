import React, { useEffect, useMemo, useState } from 'react';
import PageHeader from '../../../shared/page-header';
import { useModal } from '../../../shared/modal-views/use-modal';
import { GetUserColumns } from './user-column';
import { useColumn } from '../../../Hooks/use-column';
import { TableClass } from '../../../Constant/Classes/Classes';
import AddUserMaster from '../../../Form/master/user-master/add-user-master';
import { useDispatch, useSelector } from 'react-redux';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { searchUser } from '../../../Constant/Api/Api';
import { CompileUserMaster } from './promiss/user-promiss';
import { setUserApiJson, setUserData } from '../../../Store/Action/user-management/user-action';
import { setPagination } from '../../../Store/Action/Pagination/PaginationAction';
import ControlledTable from '../../../Component/ui/table/custom-table';
import { ScreenName } from '../../../Constant/Screen/Screen';
import { CreateUserQuery } from '../../../Utils/Utils';
import { deactiveText } from '../../../Constant/Common/common-variable';

export default function UserManagement() {
    const dispatch = useDispatch();
    const reduxUser = useSelector(state => state.UserReducer);
    const reduxPagination = useSelector(state => state.PaginationReducer);
    const reduxSelect = useSelector(state => state.SearchableSelectReducer)
    const { openModal, closeModal } = useModal();
    const [loading, setLoading] = useState(false)

    const loadData = (type) => {
        let json = reduxUser?.searchJson;
        if (type === 'init') {
            Object.assign(json, { page: 1, limit: reduxPagination?.doc?.limit });
            Object.assign(json.search, { status: { "$ne": deactiveText } })
        } else {
            Object.assign(json, { page: reduxPagination?.doc?.number, limit: reduxPagination?.doc?.limit });
        }
        setLoading(true)

        console.log('json', json);

        HitApi(json, searchUser).then((result) => {
            if (result?.success !== false) {
                CompileUserMaster(result, reduxPagination?.doc).then((compiledData) => {
                    dispatch(setUserData(compiledData));
                    dispatch(setPagination({
                        limit: json?.limit,
                        totalPages: compiledData?.totalPages,
                        number: compiledData?.number,
                        totalElements: compiledData?.totalElements,
                        screen: ScreenName?.user
                    }));
                });
            } else {
                dispatch(setUserData([]));
            }
            setLoading(false)
        });
    };

    // Memoized columns for table
    const columns = useMemo(() => GetUserColumns(loadData));
    const { visibleColumns, checkedColumns, setCheckedColumns } = useColumn(columns);

    // Initial data load on component mount
    useEffect(() => {
        if (reduxUser?.doc === null) {
            loadData('init');
        }

        console.log('reduxPagination', reduxPagination?.doc);

    }, [reduxUser]);

    return (
        <div>
            <PageHeader
                screen={ScreenName?.user}
                btnText={'Add User'}
                children={<AddUserMaster closeModal={closeModal} ApiHit={loadData} selectionType={'add-user-form'} />}
                title={'Add User'}
                columns={columns}
                checkedColumns={checkedColumns}
                setCheckedColumns={setCheckedColumns}
                json={reduxUser?.searchJson}
                setAction={setUserApiJson}
                ApiHit={loadData}
            />
            <ControlledTable
                screen={ScreenName?.user}
                variant="modern"
                isLoading={loading}
                data={reduxUser?.doc?.content}
                columns={visibleColumns}
                className={TableClass}
                json={reduxUser?.searchJson}
                setAction={setUserApiJson}
                ApiHit={loadData}
            />
        </div>
    );
}
