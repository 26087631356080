export const SET_BUILDING_DATA = 'SET_BUILDING_DATA'
export const SET_INVENTORY_DATA = 'SET_INVENTORY_DATA'
export const SET_READER_DATA = 'SET_READER_DATA'
export const SET_ZONE_DATA = 'SET_ZONE_DATA'
export const SET_LOCATION_DATA = 'SET_LOCATION_DATA'


export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA'

export const setDashboardData = (data) => ({ type: SET_DASHBOARD_DATA, value: data });



export const setSelectedBuildingData = (data) => ({ type: SET_BUILDING_DATA, value: data });
export const setSelecteInventoryData = (data) => ({ type: SET_INVENTORY_DATA, value: data });
export const setSelectedReaderData = (data) => ({ type: SET_READER_DATA, value: data });
export const setZoneData = (data) => ({ type: SET_ZONE_DATA, value: data });
export const setLocationData = (data) => ({ type: SET_LOCATION_DATA, value: data });