import { SET_ORDER_API_JSON, SET_ORDER_BUILDING_DATA, SET_ORDER_DATA, SET_ORDER_SEARCH_JSON, SET_ORDER_SELECTED_PRODUCT } from "../../Action/order/order-action"

const initialState = {
    doc: null,
    buildingData: null,
    apiJson: {
        orderStatus:'ORDER_INITIATED',
    },
    selectedProduct : [],
    searchJson: { page: 1, limit: 10, search: {} },
    timestamp: Date.now()
}

const OrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ORDER_DATA:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        case SET_ORDER_SELECTED_PRODUCT:
            return ({ ...state, selectedProduct: action.value, timestamp: Date.now() })
        case SET_ORDER_BUILDING_DATA:
            return ({ ...state, buildingData: action.value, timestamp: Date.now() })
        case SET_ORDER_API_JSON:
            return ({ ...state, apiJson: action.value, timestamp: Date.now() })
        case SET_ORDER_SEARCH_JSON:
            return ({ ...state, searchJson: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default OrderReducer;
