import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from '../../Utils/class-names'
import { RoundedCard } from '../../Constant/Classes/Classes'
import { Button, Text } from 'rizzui'
import { PermissionColors } from '../../Constant/Colors/Color'
import { setRolesAndPermission, setRolesAndPermissionApiJson, setRolesAndPermissionMainData } from '../../Store/Action/RolesAndPermission/RolesAndPermissionAction'
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import CustomButton from '../../Component/ui/buttons/custom-button'
import CustomInput from '../../Component/ui/form/input/custom-input'
import useDynamicLoading from '../../Hooks/use-dynamic-loading'
import useValidation from '../../Hooks/useValidation'
import { validationSchema } from '../../Utils/validators/validationSchema'
import { ConstructJson, ReCreateJsonForEdit } from './permission-promiss'
import { addRole, searchRole, updateRole } from '../../Constant/Api/Api'
import { HitApi } from '../../Store/Action/Api/ApiAction'
import useCustomAlertController from '../../Hooks/use-custom-alert copy'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../config/routes'

const rolesAndPermissionScheme = {
    roleName: validationSchema.string('Role Name Field is Required')
        .min(6, 'Role Name Field should be min 6 characters long'),
    permission: validationSchema.array('Permission is Required')
};

export default function CustomRoleAdd() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const reduxRoles = useSelector(state => state.RolesAndPermissionReducer)
    const [selectedParent, setSelectedParent] = useState(null)
    const { errors, validate } = useValidation(rolesAndPermissionScheme);
    const { loadingState, setDynamicLoading } = useDynamicLoading()
    const { showCustomAlert } = useCustomAlertController();

    var url = window?.location?.pathname
    var ID = url?.split('/')?.[4]

    useEffect(() => {
        if (ID && !reduxRoles?.apiJson?._id) {
            loadDefault(ID)
        }
        console.log('reduxRoles', reduxRoles);

    }, [])


    const handleNavigate = () => {
        dispatch(setRolesAndPermissionApiJson({}))
        dispatch(setRolesAndPermission(null))
        dispatch(setRolesAndPermissionMainData(null))
        setDynamicLoading({ fullPage: false })
        navigate(routes?.panel?.master?.rolesAndPermission);
    }

    const loadDefault = (_id) => {
        var json = { page: 1, limit: 1, search: { _id: _id } }
        setDynamicLoading({ fullPage: true })
        HitApi(json, searchRole).then((result) => {
            console.log('edit result', result);
            if (result?.content?.[0]) {
                const modifiedJson = ReCreateJsonForEdit(result?.content?.[0], reduxRoles?.doc);
                if (modifiedJson) {
                    var tj = {
                        _id: _id,
                        roleName: result?.content?.[0]?.roleName,
                        permission: modifiedJson
                    }
                    setDynamicLoading({ fullPage: false })
                    dispatch(setRolesAndPermissionApiJson(tj))
                }
            } else {
                setDynamicLoading({ fullPage: false })
            }
        })
    }


    const handlePermsClick = (parentItem, perms, index) => {
        let json = [...reduxRoles?.doc];
        let indexedDoc = json?.[index];
        let permission = indexedDoc?.permission?.[0];
        let currentPerms = permission?.[perms];

        switch (perms) {
            case 'read':
                currentPerms.allowed = !currentPerms.allowed;
                if (!currentPerms.allowed) {
                    permission.write.allowed = false;
                    if (permission?.delete) {
                        permission.delete.allowed = false;
                    }
                    if (currentPerms.allowed === false) {
                        indexedDoc?.child?.map((ele) => {
                            let childPermission = ele?.permission?.[0]
                            childPermission.read.allowed = false;
                            childPermission.write.allowed = false;
                            if (childPermission?.delete) {
                                childPermission.delete.allowed = false;
                            }
                        })
                    }
                }
                break;

            case 'write':
                if (permission?.read?.allowed) {
                    currentPerms.allowed = !currentPerms.allowed;
                    if (!currentPerms.allowed) {
                        if (permission?.delete) {
                            permission.delete.allowed = false;
                        }
                    }
                    indexedDoc?.child?.map((ele) => {
                        let childPermission = ele?.permission?.[0];
                        childPermission.write.allowed = false;
                        if (childPermission?.delete) {
                            childPermission.delete.allowed = false;
                        }
                    });
                }
                break;

            case 'delete':
                if (permission?.read?.allowed && permission?.write?.allowed) {
                    currentPerms.allowed = !currentPerms.allowed;

                }
                break;

            default:
                console.warn('Unknown permission type:', perms);
        }
        dispatch(setRolesAndPermission(json));
    };

    const handleChildPermsClick = (parentItem, childItem, perms, index, childIndex) => {
        let json = [...reduxRoles?.doc];
        let indexedDoc = json?.[index];
        let childDoc = indexedDoc?.child?.[childIndex];
        let paretPermission = indexedDoc?.permission?.[0];
        let paretCurrentPerms = paretPermission?.[perms];
        let permission = childDoc?.permission?.[0];
        let currentPerms = permission?.[perms];

        switch (perms) {
            case 'read':
                if (paretCurrentPerms.allowed) {
                    currentPerms.allowed = !currentPerms.allowed;
                    if (!currentPerms.allowed) {
                        permission.write.allowed = false;
                        if (permission?.delete) {
                            permission.delete.allowed = false;
                        }
                    }
                }
                break;
            case 'write':
                if (paretCurrentPerms.allowed) {
                    if (permission?.read?.allowed) {
                        currentPerms.allowed = !currentPerms.allowed;
                        if (!currentPerms.allowed) {
                            if (permission?.delete) {
                                permission.delete.allowed = false;
                            }
                        }
                    }
                }
                break;

            case 'delete':
                if (paretCurrentPerms.allowed) {
                    if (permission?.read?.allowed && permission?.write?.allowed) {
                        currentPerms.allowed = !currentPerms.allowed;
                    }
                }
                break;

            default:
                console.warn('Unknown permission type:', perms);

        }
        dispatch(setRolesAndPermission(json));
    };

    const handleChildLoad = (parentItem, index) => {
        if (selectedParent?.value === parentItem?.value) {
            setSelectedParent(null);
        } else {
            setSelectedParent(parentItem);
        }
    }

    let parent, child;

    if (reduxRoles?.doc) {
        parent = reduxRoles?.doc?.map((parentItem, index) => {
            var parentPermission = Object.keys(parentItem?.permission?.[0])
            const isReadAllowed = parentItem?.permission?.[0]?.read?.allowed;
            const isWriteAllowed = parentItem?.permission?.[0]?.write?.allowed;
            return <div>
                <div className='flex justify-between my-6'>
                    <strong className="bg-gray-200 text-black font-medium py-3 px-4 rounded-md w-40 flex justify-between items-center capitalize" onClick={() => handleChildLoad(parentItem)}>
                        {parentItem?.value}
                        {selectedParent?.value === parentItem?.value ? <FaChevronDown /> : <FaChevronRight />}
                    </strong>
                    <div>
                        <label className='space-x-3 space-y-3'>{parentPermission?.map((perms) => {
                            var permsValue = parentItem?.permission?.[0]?.[perms]?.allowed
                            const isClickable = perms === 'read' || (perms === 'write' && isReadAllowed) || (perms === 'delete' && isReadAllowed && isWriteAllowed);
                            return <button className={cn(permsValue ? PermissionColors?.[perms] : 'bg-gray-100 text-gray-500', 'cursor-pointer p-3 m-1 uppercase', !isClickable && 'opacity-50 cursor-not-allowed')} onClick={() => handlePermsClick(parentItem, perms, index)}>
                                {perms}
                            </button>
                        })}
                        </label>
                    </div>
                </div>
                {selectedParent?.value === parentItem?.value && selectedParent?.child?.map((childItem, childIndex) => {
                    var childPermission = Object.keys(parentItem?.permission?.[0])
                    const isChildReadAllowed = childItem?.permission?.[0]?.read?.allowed;
                    const isChildWriteAllowed = childItem?.permission?.[0]?.write?.allowed;
                    return <div key={childIndex}>
                        <div className='flex justify-between my-8'>
                            <div><label className='cursor-pointer capitalize'>{childItem?.value}</label></div>
                            <div>
                                <label className='space-x-3 space-y-3'>{childPermission?.map((perms) => {
                                    var permsValue = childItem?.permission?.[0]?.[perms]?.allowed
                                    const isClickable = perms === 'read' || (perms === 'write' && isChildReadAllowed) || (perms === 'delete' && isChildReadAllowed && isChildWriteAllowed);
                                    return <label className={cn(permsValue ? PermissionColors?.[perms] : 'bg-gray-100 text-gray-500', 'cursor-pointer p-3 m-1 uppercase', !isClickable && 'opacity-50 cursor-not-allowed')} onClick={() => handleChildPermsClick(parentItem, childItem, perms, index, childIndex)}>
                                        {perms}
                                    </label>
                                })}
                                </label>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        ConstructJson(reduxRoles?.doc).then((FinalJson) => {
            if (FinalJson) {
                var json = {
                    roleName: reduxRoles?.apiJson?.roleName,
                    allowedEndPoints: FinalJson?.allowedEndPoints,
                    permission: FinalJson?.permission,
                }
                const validationErrors = validate(json);
                console.log('json', json);
                if (Object.keys(validationErrors).length === 0) {
                    setDynamicLoading({ fullPage: true })
                    if (ID) {
                        Object.assign(json, { _id: ID })
                        HitApi(json, updateRole).then((result) => {
                            showCustomAlert(result)
                            setDynamicLoading({ fullPage: false })
                            setTimeout(() => { handleNavigate() }, 300);
                        })
                    } else {
                        HitApi(json, addRole).then((result) => {

                            console.log('result = = = ', result);
                            showCustomAlert(result)
                            setDynamicLoading({ fullPage: false })
                            setTimeout(() => { handleNavigate() }, 300);
                        })
                    }
                }
            } else {
                setDynamicLoading({ fullPage: false })
            }
        })
    }


    return (
        <div className={cn(RoundedCard)}>
            <div className="space-y-5 lg:space-y-6">
                <div className='grid grid-cols-2 gap-4'>
                    <CustomInput
                        name="roleName"
                        inputType={'text'}
                        label="Role Name"
                        value={reduxRoles?.apiJson?.roleName}

                        error={errors}
                        reduxState={reduxRoles?.apiJson}
                        setAction={setRolesAndPermissionApiJson}
                    />
                </div>
                <div className='flex flex-col'>
                    <Text as="h6" className='font-bold'>Access</Text>
                    {errors?.permission && <span className='text-red text-[13px] mt-0.5 rizzui-input-error-text'>{errors?.permission}</span>}
                </div>
                <div className='overflow-auto '>{parent}</div>
                <div className='flex gap-3 justify-end'>
                    <CustomButton text={'Cancel'} className={''} />
                    <CustomButton type={'submit'} className={''} loading={loadingState?.doc?.addRole} text={ID && 'Update' || 'Submit'} onClick={handleSubmit} />
                </div>
            </div>
        </div>
    )
}
