import React, { useEffect, useState } from 'react'
import DummyData from './dummy-data.json'
import useCompileChartData from '../../../../Hooks/use-compile-chart-data';

import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import SearchableSelect from '../../form/select/SearchableSelect';
import { ReportTypeOptions } from '../../../../Constant/Common/Common';
import CustomDatePicker from '../../date-and-time/date-picker';
import { useDispatch, useSelector } from 'react-redux';
import { setChartApi } from '../../../../Store/Action/common/chart/chart-action';
import { setSearchableSelectSelectedData } from '../../../../Store/Action/common/searcheable-select/searcheable-select-action';

export default function BarChart({title }) {
    const dispatch = useDispatch()
    const reduxChart = useSelector(state => state.ChartReducer)
    const reduxSelect = useSelector(state => state.SearchableSelectReducer)
    const [type, setType] = useState(reduxChart?.apiJson?.type)
    const [selectedDate, setSelectedDate] = useState(Date.now())
    const [compiledData, setCompiledData] = useState(null)
    const fetchData = useCompileChartData(DummyData, reduxChart?.apiJson?.type, reduxChart?.apiJson?.date);
    const selectionType = 'chart-type'
   
    const options = {
        chart: { type: 'column' },
        title: { text: title || 'No Title' },
        xAxis: { title: { text: 'Start Date' }, categories: compiledData?.map((ele) => ele?.label), crosshair: true, accessibility: { description: 'Countries' } },
        yAxis: { min: 0, title: { text: null }, stackLabels: { enabled: true } },
        series: [{ name: 'Tags', data: compiledData?.map((ele) => ele?.length) },],
        plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
        lang: {
            noData: 'Nichts zu anzeigen'
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030'
            }
        }
    }

    useEffect(() => {
        setCompiledData(fetchData);
        loadData()
    }, [type, selectedDate, fetchData]);

    const loadData = () => {
        var selected = reduxSelect?.selected
        var findItem = selected?.find(Obj=>Obj?.selectionType === selectionType && Obj?.label === type)
        console.log('findItem', findItem);
       
        dispatch(setSearchableSelectSelectedData([...selected,
        { label: reduxChart?.apiJson?.type, label: reduxChart?.apiJson?.type, name: 'type', selectionType: selectionType }
        ]))

        console.log('reduxSelect', reduxSelect?.selected);
    }

    const handleChange = (e) => {
        const { label, value, _id } = e
        var json = reduxChart?.apiJson
        Object.assign(json, { type: value })
        dispatch(setChartApi(json))

        setType(value)
    }

    const handleDateChange = (date) => {
        var json = reduxChart?.apiJson
        Object.assign(json, { date: date })
        dispatch(setChartApi(json))


        // setSelectedDate(date)
    }

    return (
        <div className='border bg-white p-10'>
            <div className='grid grid-cols-1 gap-4'>
                <div>
                    <div className='flex justify-end space-x-2'>
                        <div><SearchableSelect className={'w-40'} name={'type'} label={'Report Range'} selectionType={selectionType} defaultOptions={ReportTypeOptions} getFieldName={'value'} reduxState={reduxChart?.apiJson} setAction={setChartApi} onChange={handleChange} /></div>
                        <div><CustomDatePicker className={'w-40'} dateFormat={type === 'daily' || type === 'weekly'?"MMM, yyyy": type === 'monthly'?'yyyy':'d MMM yyyy'} name={'date'} label={'Date'} showMonthYearPicker={type === 'daily' || type === 'weekly'} showYearPicker={type === 'monthly'} reduxState={reduxChart?.apiJson} setAction={setChartApi} onChange={handleDateChange} /></div>
                    </div>
                    <div className='mt-6'>
                        <HighchartsReact highcharts={Highcharts} options={options} />
                    </div>
                </div>
            </div>
        </div>
    )
}
