import React from 'react'
import { setServiceRequestData } from '../store/Action/ServiceMasterAction'
import { useDispatch, useSelector } from 'react-redux'
import { PlusCircle, Trash } from 'lucide-react';
import { Colors } from '../../../../Constant/Colors/Color';

function Auth() {

    const ServiceMasterReducer = useSelector(state => state.ServiceMasterReducer);

    const dispatch = useDispatch()

    const onChnageAuthType = (value) => {
        var oldJson = ServiceMasterReducer?.requestDoc
        oldJson.request.auth.type = value
        dispatch(setServiceRequestData(oldJson))
    }

    const removeAuthBearer = (i) => {
        var oldJson = ServiceMasterReducer?.requestDoc
        oldJson.request.auth.bearer.splice(i, 1)
        dispatch(setServiceRequestData(oldJson))
    }

    const onClickAddMoreAuthBearer = () => {
        var oldJson = ServiceMasterReducer?.requestDoc
        oldJson.request.auth.bearer.push({ key: '', value: '' })
        dispatch(setServiceRequestData(oldJson))
    }

    const onChange = (value, name, index) => {
        var oldJson = ServiceMasterReducer?.requestDoc

        console.log('oldJson', oldJson);

        oldJson.request.auth.bearer[index][name] = value
        dispatch(setServiceRequestData(oldJson))
    }

    return (
        <div className='m-5'>
            <div>
                <p>Type</p>
                <select onChange={(e) => onChnageAuthType(e.target.value)} className="col-span-1 block w-32 rounded-md border-gray-300 px-2 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                    <option selected={ServiceMasterReducer?.requestDoc?.request?.auth?.type === 'Bearer'}>Bearer</option>
                    <option selected={ServiceMasterReducer?.requestDoc?.request?.auth?.type === 'Basic Auth'}>Basic Auth</option>
                </select>
            </div>
            <div className='mt-5'>
                {
                    ServiceMasterReducer?.requestDoc?.request?.auth?.bearer?.map((ele, i) => {
                        return (

                            ServiceMasterReducer?.requestDoc?.request?.auth?.type !== 'bearer'?
                            <div className='flex gap-5 mb-2'>
                                <input className='w-full block rounded-md border-gray-300 px-2 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50' onChange={(e) => onChange(e.target.value, 'key', i)} value={ele?.key} placeholder='Username' />
                                <input className='w-full block rounded-md border-gray-300 px-2 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50' onChange={(e) => onChange(e.target.value, 'value', i)} value={ele?.value} placeholder='Password' />
                                {/* <Trash className='hover:text-red-500 cursor-pointer' size={40} onClick={() => removeAuthBearer(i)} /> */}
                            </div>
                            :
                            <input className='w-full block rounded-md border-gray-300 px-2 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50' onChange={(e) => onChange(e.target.value, 'value', i)} value={ele?.value} placeholder='Value' />
                        )
                    })
                }
            </div>
            {/* <div className='flex justify-end'>
                <p style={{color:Colors.LOGINRED}} className='p-2 underline w-max flex text-base cursor-pointer' onClick={() => onClickAddMoreAuthBearer()}>Add More <PlusCircle /></p>
            </div> */}
        </div>
    )
}

export default Auth