import React, { useEffect, useMemo } from 'react'
import PageHeader from '../../shared/page-header'
import { ScreenName } from '../../Constant/Screen/Screen'
import { useDispatch, useSelector } from 'react-redux'
import { HitApi } from '../../Store/Action/Api/ApiAction'
import SearchableSelect from '../../Component/ui/form/select/SearchableSelect'
import { searchBuilding, searchOrder } from '../../Constant/Api/Api'
import { getAuthenticatedUser, getBuildingFromStorage, setBuildingToStorage } from '../../Storage/Storage'
import ControlledTable from '../../Component/ui/table/custom-table'
import useDynamicLoading from '../../Hooks/use-dynamic-loading'
import { useColumn } from '../../Hooks/use-column'
import { setPagination } from '../../Store/Action/Pagination/PaginationAction'
import { setOrderApiJson, setOrderData } from '../../Store/Action/order/order-action'
import { TableClass } from '../../Constant/Classes/Classes'
import { routes } from '../../config/routes'
import { Button } from 'rizzui'
import { Colors } from '../../Constant/Colors/Color'
import { PiPlusCircleBold } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { GetOrderColumn } from '../order/order-column'
import { CompileOrderData } from '../order/promiss/order-promiss'
import { outboundText } from '../../Constant/Common/common-variable'
import { setSearchableSelectSelectedData } from '../../Store/Action/common/searcheable-select/searcheable-select-action'
import OrderTable from '../../Component/order-table/order-table'

export default function OutboundOrder() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const reduxOrder = useSelector(state => state.OrderReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer);
    const reduxSelect = useSelector(state => state.SearchableSelectReducer)

    const user = getAuthenticatedUser()
    const { loadingState, setDynamicLoading } = useDynamicLoading()

    useEffect(() => {
        if (reduxOrder?.doc === null && !reduxOrder?.apiJson?.dispatchFrom) {
            CheckForStorage()
        }

        console.log('reduxOrder', reduxOrder);

    }, [reduxOrder])

    const CheckForStorage = () => {
        var selectedBuilding = getBuildingFromStorage()
        if (selectedBuilding && selectedBuilding?.selectionType === outboundText) {
            console.log('selectedBuilding', selectedBuilding);
            dispatch(setSearchableSelectSelectedData([selectedBuilding]))
            handleBuilingChange(selectedBuilding)
        }
    }

    const handleBuilingChange = (e) => {
        dispatch(setOrderData([]));
        const { label, value, _id } = e
        var apiJson = reduxOrder?.apiJson
        Object.assign(apiJson, {
            dispatchFrom: _id,
            dispatchFromName: value,
            orderStatus: 'ORDER_INITIATED', orderType: outboundText
        })

        console.log('apiJson', apiJson);

        dispatch(setOrderApiJson(apiJson))
        setBuildingToStorage(e)
    }

    const handleClear = () => {
        dispatch(setOrderData([]));
    }

    const handleNavigate = () => {
        console.log('reduxOrder?.apiJson?.orderType', reduxOrder?.apiJson?.orderType);

        var url = routes?.panel?.order?.createOuboundOrder + '/' + reduxOrder?.apiJson?.dispatchFrom + '/' + reduxOrder?.apiJson?.orderType
        console.log(url);
        // // navigate(url)
        window.location.href = url
    }


    return (
        <div>
            <PageHeader metaTitle={'Inbound'} disbleExport />
            <div className='flex justify-between items-end'>
                <div className=''>
                    <SearchableSelect name={'buildingIds'} label={'Select Building'} selectionType={outboundText} api={searchBuilding} getFieldName={'buildingName'} dynamicSearch={{ userIds: { "$in": [user?.userId] } }} onClear={handleClear} onChange={(e) => handleBuilingChange(e)} />
                </div>
                {reduxOrder?.apiJson?.dispatchFrom && <div>
                    <Button as="span" type="button" className="w-full @lg:w-auto cursor-pointer" onClick={() => handleNavigate()} style={{ background: Colors.LOGINRED }} > <PiPlusCircleBold className="me-2 h-4 w-4" /> Create </Button>
                </div>}
            </div>
            <div><OrderTable type={false} buildingId={reduxOrder?.apiJson?.dispatchFrom} /></div>
        </div>
    )
}
