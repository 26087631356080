import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { ActionIcon, Button, Text } from "rizzui";
import { HeaderCell } from "../../../../Component/ui/table/table";
import TableActions from "../../../../Component/ui/table/TableActions";
import DeletePopover from "../../../../shared/delete-popover";
import { useDispatch, useSelector } from "react-redux";
import { setOrderApiJson } from "../../../../Store/Action/order/order-action";
import PencilIcon from "../../../../Constant/Icons/pencil";
import { useModal } from "../../../../shared/modal-views/use-modal";
import SearchAndAddProduct from "./search-and-add-product";
import { setSearchableSelectSelectedData } from "../../../../Store/Action/common/searcheable-select/searcheable-select-action";
import { inboundText } from "../../../../Constant/Common/common-variable";

export const GetAddedProductColumn = (reduxState) => {
    const dispatch = useDispatch()
    const reduxSelect = useSelector(state => state.SearchableSelectReducer)
    const [loadingRows, setLoadingRows] = useState({});
    const { openModal, closeModal } = useModal();


    const handleDelete = (row) => {
        console.log('row', row);
        var filterItems = reduxState?.productIds?.filter(Obj => Obj?.productId?._id !== row?.productId?._id)
        Object.assign(reduxState, { productIds: filterItems })
        dispatch(setOrderApiJson(reduxState))
        console.log('filterItems', filterItems);
    }

    const onEdit = (row, index) => {
        var data = reduxState?.productIds?.[index]

        console.log('row - - - - ', row);

        var tempSelected = [
            { label: data?.['productId']?.productName, value: data?.['productId']?._id, name: 'productId', selectionType: 'productId' },
            { label: row?.['zoneIds']?.value, value: row?.['zoneIds']?._id, name: 'zoneIds', selectionType: 'zoneIds' },
            { label: row?.['locationIds']?.value, value: row?.['locationIds']?._id, name: 'locationIds', selectionType: 'locationIds' },
        ]

        dispatch(setSearchableSelectSelectedData(tempSelected))

        console.log('tempSelected', tempSelected);
        openModal({ view: <SearchAndAddProduct index={index} mode={'edit'} zoneId={row?.['zoneIds']?._id} />, customSize: '800px', title: 'Add Product' });
    }

    const renderCell = (value, row, columnKey) => (
        loadingRows[row?.index] ? <Skeleton /> :
            <Text>{reduxState?.orderType === inboundText ? row?.productId?.[columnKey] || '---' : row?.productId?.productId?.[columnKey] || '---'}</Text>
    );

    // renderCell(value, row, <Text>{index + 1 || '---'}</Text>)

    return [
        {
            title: <HeaderCell title="#" />,
            dataIndex: 'index',
            key: 'index',
            width: 10,
            render: (value, row, index) => console.log('row', row),
        },
        {
            title: <HeaderCell title="_id" />,
            dataIndex: '_id',
            key: '_id',
            width: 10,
            render: (value, row, index) => renderCell(value, row, '_id'),
        },
        {
            title: <HeaderCell title="Product Code" />,
            dataIndex: 'productCode',
            key: 'productCode',
            width: 50,
            render: (value, row, index) => renderCell(value, row, 'productCode'),
        },
        {
            title: <HeaderCell title="Product Name" />,
            dataIndex: 'productName',
            key: 'productName',
            width: 50,
            render: (value, row, index) => renderCell(value, row, 'productName'),
        },
        {
            title: <HeaderCell title="Product Grade" />,
            dataIndex: 'grade',
            key: 'grade',
            width: 50,
            render: (value, row, index) => renderCell(value, row, 'grade')
        },
        {
            title: <HeaderCell title="Product Qty" />,
            dataIndex: 'quantity',
            key: 'quantity',
            width: 50,
            render: (value, row, index) => <Text>{row?.quantity || '---'}</Text>
        },
        {
            title: <HeaderCell title="Actions" className="font-extrabold" />,
            dataIndex: 'action',
            key: 'action',
            width: 300,
            render: (_, row, index) => <div className="space-x-2">
                <ActionIcon as="span" size="sm" variant="outline" className="hover:text-gray-700" onClick={() => onEdit(row, index)}>
                    <PencilIcon className="h-4 w-4" />
                </ActionIcon>
                <DeletePopover
                    title="Delete Product"
                    description="Are you sure you want to delete this product from order?"
                    onDelete={() => handleDelete(row)}
                />
            </div>,
        },
    ];
};
