import React, { useMemo } from 'react'
import ControlledTable from '../../../Component/ui/table/custom-table'
import { GetProductColumn } from './product-column';
import { useColumn } from '../../../Hooks/use-column';

export default function ProductList({ row }) {
    console.log('row', row?.productIds);
    const columns = useMemo(() => GetProductColumn(row))
    const { visibleColumns } = useColumn(columns);
          

    





    return ( 
        <div className='p-10'>
            <ControlledTable
                // isLoading={loadingState?.doc?.order}
                data={row?.productIds}
                columns={visibleColumns}
            // className={TableClass}
            // json={reduxOrder?.searchJson}
            // setAction={setOrderApiJson}
            // ApiHit={loadData}
            disablePagination
            />
        </div>
    ) 
}
