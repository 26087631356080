import React, { useEffect, useState } from 'react'
import { HitApi } from '../../Store/Action/Api/ApiAction'
import { searchDeviceProfile } from '../../Constant/Api/Api'
import CustomBarChart from '../../Component/ui/chart/bar-chart/bar-chart'

export default function Test() {
  const [data, setData] = useState(null)

  useEffect(()=>{
    
  },[])

  return (
    <div className='grid grid-cols-1'><CustomBarChart title={'Tag History'}/></div>
  )
}
