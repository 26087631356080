import { Text } from 'rizzui';
import { HeaderCell } from '../../Component/ui/table';
import AddSiteMaster from '../../Form/master/site-master/add-site-master';
import { EditScreen } from '../../shared/edit-screen';
import { HitApi } from '../../Store/Action/Api/ApiAction';
import { deleteSite, searchBuilding, updateSite } from '../../Constant/Api/Api';
import { getFormattedDate } from '../../Utils/Utils';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import TableActions from '../../Component/ui/table/TableActions';
import { _id, deactiveText } from '../../Constant/Common/common-variable';
import { ScreenName } from '../../Constant/Screen/Screen';
import useCustomAlertController from '../../Hooks/use-custom-alert copy';
import TagHistory from './tagHistory';

export const GetDashboardTableColumns = (openModal, closeModal, ApiHit) => {
  const { showCustomAlert } = useCustomAlertController();
  const [loadingRows, setLoadingRows] = useState({});

  const handleDelete = async (row) => {
    setLoadingRows((prev) => ({ ...prev, [row.index]: true }));
    Object.assign(row, {status : deactiveText, updatedBy : ''})
    try {
      const result = await HitApi(row, updateSite);
      console.log('result', result);
      showCustomAlert(result)
      if (ApiHit) { ApiHit(); }
      
    } catch (err) {

    } finally {
      setLoadingRows((prev) => ({ ...prev, [row.index]: false }));
    }
    
  };

  const renderCell = (value, row, content) => (
    loadingRows[row.index] ? <Skeleton /> : content
  );

  return [
    {
        title: <HeaderCell title="Product Name" className="font-extrabold" />,
        dataIndex: 'tagInfo',
        key: 'tagInfo',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
    {
        title: <HeaderCell title="Opreation Status" className="font-extrabold" />,
        dataIndex: 'opreationStatus',
        key: 'opreationStatus',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
    {
      title: <HeaderCell title="Batch Number" className="font-extrabold" />,
      dataIndex: 'batchNumber',
      key: 'batchNumber',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
        title: <HeaderCell title="Tag Number" className="font-extrabold" />,
        dataIndex: 'rfidTag',
        key: 'rfidTag',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
    
    {
        title: <HeaderCell title="Status" className="font-extrabold" />,
        dataIndex: 'status',
        key: 'status',
        width: 100,
        render: (value, row) => renderCell(value, row, (
          <Text className="font-medium text-gray-700">{value || '---'}</Text>
        )),
      },
    {
      title: <HeaderCell title="Movement Status" className="font-extrabold" />,
      dataIndex: 'movementStatus',
      key: 'movementStatus',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
        title: <HeaderCell title="Actions" className="font-extrabold" />,
        dataIndex: 'action',
        key: 'action',
        width: 300,
        render: (_, row) => renderCell(null, row, (
          <TableActions
            screen={ScreenName?.siteMaster}
            row={row}
            onEdit={(rowData) => EditScreen(openModal, closeModal, rowData, 'Inventory Tag history', TagHistory, 800, ApiHit)}
          />
        )),
      },
  ];
};
