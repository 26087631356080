import React, { useEffect, useState } from "react";
import { HitApi } from "../../Store/Action/Api/ApiAction";
import { searchTag, searchTagHistory } from "../../Constant/Api/Api";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardData } from "../../Store/Action/dashbaord/DashboardAction";
import { compareObjects, getTimeDifference } from "../../Utils/Utils";
import { TableClass } from "../../Constant/Classes/Classes";
import ControlledTable from "../../Component/ui/table/custom-table";
import { Colors } from "../../Constant/Colors/Color";
import CustomButton from "../../Component/ui/buttons/custom-button";

const TagHistory = ({ openModal, closeModal, row, title, ScreenName, customSize, ApiHit, navigate, navigateTo }) => {

    const dashbaordRedux = useSelector(state => state.DashboardReducer)
    const dispatch = useDispatch()

    const [compareData, setCompareData] = useState(null)

    useEffect(() => {
        if (dashbaordRedux?.doc?.tagHistory === null) {
            fetchTagHistory()
        }
    }, [])

    const fetchTagHistory = () => {
        const json = {
            page: 1,
            limit: 10,
            search: {
                parentId: row?._id
            }
        }
        HitApi(json, searchTagHistory).then(res => {
            console.log('res', res);
            if (res?.content?.length > 0) {
                const oldData = dashbaordRedux?.doc
                oldData.tagHistory = res?.content
                dispatch(setDashboardData(oldData))
                const allChanges = [];
                for (let i = 0; i < res?.content?.length - 1; i++) {
                    const changes = compareObjects(
                        res?.content[i],
                        res?.content[i + 1],
                    );
                    if (changes.length > 0) {
                        allChanges.push({
                            from: i,
                            to: i + 1,
                            changes: changes,
                            updatedAt: res?.content[i + 1]?.updatedAt,
                            updatedBy: res?.content[i + 1]?.updatedBy,
                            createdAt: res?.content[i + 1]?.createdAt,
                            createdBy: res?.content[i + 1]?.createdBy,
                        });
                    }
                }
                setCompareData(allChanges)
            }
        })
    }

    const HistorySummary = ({ updatedArr, createdBy, dateAgo, isCreated }) => {
        return (
            <div className="flex mb-5 mt-5">
                <div>
                    <p style={{ backgroundColor: Colors.LOGINRED }} className="w-6 h-6 rounded-xl pt-0.5 text-center text-white">A</p>
                </div>
                <div>
                    <div className="flex ml-3 mt-0.5">
                        <p className="text-black font-bold">{createdBy}&nbsp;</p>
                        <p className="text-gray-400 font-bold">-&nbsp;{getTimeDifference(dateAgo)} <span style={{ color: Colors.LOGINRED }}>({isCreated ? 'Created' : 'Updated'})</span></p>
                    </div>
                    <div className="flex ml-3 mt-1">
                        <ui>
                            {
                                updatedArr?.map((ele, i) => {
                                    return (
                                        <li className="text-black">{ele}</li>
                                    )
                                })
                            }
                        </ui>
                    </div>
                </div>
            </div>
        )
    }

    const onClickCancel = () => {
        const oldData = dashbaordRedux?.doc
        oldData.tagHistory = null
        dispatch(setDashboardData(oldData))
        setCompareData(null)
        closeModal()
    }

    return (
        <div className="p-5">
            {
                dashbaordRedux?.doc?.tagHistory !== null &&
                dashbaordRedux?.doc?.tagHistory?.[0] &&
                <div>
                    <HistorySummary createdBy={"Aman"} isCreated={true} dateAgo={dashbaordRedux?.doc?.tagHistory?.[0]?.createdAt} updatedArr={['Batch Number - ' + dashbaordRedux?.doc?.tagHistory?.[0]?.batchNumber]} />
                </div>
            }
            {
                compareData !== null &&
                compareData?.map((ele, index) => {
                    var updatedArr = []
                    ele?.changes?.map((item, i) => {
                        updatedArr?.push(item?.key + ' change to ' + item?.newValue)
                    })
                    console.log('updatedArr', updatedArr);

                    return (
                        <HistorySummary createdBy={"Aman"} isCreated={false} dateAgo={ele?.updatedAt} updatedArr={updatedArr} />
                    )
                })
            }
            <CustomButton variant={'flat'} onClick={() => onClickCancel()} text='Cancel' />
        </div>
    )
}

export default TagHistory;