import React from 'react'
import CustomInput from '../../../Component/ui/form/input/custom-input'
import { useDispatch, useSelector } from 'react-redux'
import useValidation from '../../../Hooks/useValidation'
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect'
import { useCallback } from 'react'
import CustomButton from '../../../Component/ui/form/button/custom-button'
import { HitApi } from '../../../Store/Action/Api/ApiAction'
import useAlertController from '../../../Hooks/use-alert-controller'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { setConfigurationMasterApiJson, setConfigurationMasterData } from '../../../Store/Action/master/configuration-master/configuration-master-action'
import { configurationMasterSchema } from '../../../Utils/validators/master/configuration-master/configuration-master-scheema'
import ConfigMasterTabs from './config-master-tabs/config-master-tabs'
import { addMqttConfig, searchMqttConfig, updateMqttConfig } from '../../../Constant/Api/Api'
import { CompileConfigurationMaster } from './compileConfigurationMaster'
import { CompileConfigurationJson } from '../../../WebView/master/configuration-master/tabs-summary/CompileJson'




export default function AddConfigurationMaster({ row, closeModal, ApiHit }) {
  var url = window.location.pathname
  var ID = url.split('/')[4]



  const reduxConfiguration = useSelector(state => state.ConfigurationMasterReducer)

  const { errors, validate } = useValidation(configurationMasterSchema);
  const reduxUser = useSelector(state => state.UserReducer);
  const [loading, setLoading] = React.useState(false)
  const { showCustomAlert } = useAlertController();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const handleOnChange = useCallback((e, name) => {
    const { _id, value } = e;


    const newJson = { [name]: name === 'siteIds' ? _id : value };
    const updatedJson = { ...reduxConfiguration?.apiJson, ...newJson };
    dispatch(setConfigurationMasterApiJson(updatedJson));
  }, [dispatch, reduxConfiguration?.apiJson]);

  const typeOption = [
    { _id: 'MQTT', label: 'MQTT', value: 'MQTT' },
    { _id: 'MQTT', label: 'MQTT', value: 'MQTT' },
    { _id: 'MQTT', label: 'MQTT', value: 'MQTT' },
  ]

  useEffect(() => {
    if (ID) {
      loadDefault(ID)
    }
  }, [])


  const loadDefault = () => {

    var json = reduxConfiguration?.searchJson
    json.search._id = ID
    HitApi(json, searchMqttConfig).then((result) => {
      if (result) {

        CompileConfigurationMaster(result?.content?.[0]).then((CompiledData) => {
          if (CompiledData) {
            dispatch(setConfigurationMasterApiJson(CompiledData))
          }
        })
      }
    })
  }


  const handleSubmit = (e) => {
    var json = reduxConfiguration?.apiJson;

    const validationErrors = validate(json);

    console.log("validationErrors", validationErrors);
    if (Object?.keys(validationErrors).length === 0) {
      CompileConfigurationJson(reduxConfiguration?.apiJson).then(compiledData => {
        if (ID) {
          Object.assign(compiledData, { _id: ID })
          HitApi(compiledData, updateMqttConfig).then((result) => {

            setLoading(false)
            if (result?.status === 200) {
              showCustomAlert({
                type: 'success',
                title: 'Success!',
                message: result?.message,
              });
            }
            navigate('/master/configuration');
            handleClose()

          })
        } else {
          HitApi(compiledData, addMqttConfig).then((result) => {
            setLoading(false)


            if (result?.success !== false) {
              showCustomAlert({
                type: 'success',
                title: 'Success!',
                message: result?.message,
              });
              navigate('/master/configuration');
              handleClose()

              if (ApiHit) { ApiHit() }
            } else {
              showCustomAlert({
                type: 'error',
                title: 'Error!',
                message: result?.message,
              });
            }

          })
        }
      })

    }






  };


  const handleClose = () => {
    dispatch(setConfigurationMasterData(null))
    dispatch(setConfigurationMasterApiJson({}))
  }

  return (
    <div>
      <div className='p-10 rounded-xl bg-white mt-10'>
        <div className="space-y-5 lg:space-y-6">
          <div className='grid grid-cols-3 gap-4 '>
            <CustomInput maxLength={15} name="name" label="End point name" value={reduxConfiguration?.apiJson?.productName} error={errors} reduxState={reduxConfiguration?.apiJson} setAction={setConfigurationMasterApiJson} validate={validate} />
            <CustomInput maxLength={15} name="description" label="End point Description" value={reduxConfiguration?.apiJson?.description} error={errors} reduxState={reduxConfiguration?.apiJson} setAction={setConfigurationMasterApiJson} validate={validate} />
            <SearchableSelect searchable name="type" label="End point type" defaultOptions={typeOption} value={reduxConfiguration?.apiJson?.customerCity} error={errors} reduxState={reduxConfiguration?.apiJson} setAction={setConfigurationMasterApiJson} validate={validate} onChange={(e) => handleOnChange(e, 'type')} />
          </div>
        </div>
      </div>
      <div className='p-10 rounded-xl bg-white mt-5'>
        <ConfigMasterTabs error={errors} validate={validate} />
      </div>
      <div className='flex gap-3 justify-end mt-5 p-10'>
        <CustomButton title={'Back'} variant='flat' className={''} onClick={() => window.location.pathname = '/master/configuration'} />
        <CustomButton type={'submit'} title={ID ? 'Update' : 'Submit'} loading={loading} onClick={handleSubmit} />
      </div>
    </div>
  )
}
