import React, { useEffect, useState } from 'react'
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect'
import { searchUser, updateBuilding } from '../../../Constant/Api/Api'
import CustomButton from '../../../Component/ui/buttons/custom-button'
import SearchableMultiSelect from '../../../Component/ui/form/select/searchable-multi-select'
import { useDispatch, useSelector } from 'react-redux'
import { setBuildingMasterApiJson } from '../../../Store/Action/master/building-master/building-master-action'
import useValidation from '../../../Hooks/useValidation'
import useCustomAlertController from '../../../Hooks/use-custom-alert'
import { validationSchema } from '../../../Utils/validators/validationSchema'
import { HitApi } from '../../../Store/Action/Api/ApiAction'
import { useModal } from '../../../shared/modal-views/use-modal'
import useDynamicLoading from '../../../Hooks/use-dynamic-loading'


export const builingMasterSchema = {
    userIds: validationSchema.string()?.optional()
};


export default function UserSearchAndAdd({ row }) {
    const dispatch = useDispatch()
    const reduxBuilding = useSelector(state => state.BuildingMasterReducer)
    const { errors, validate } = useValidation(builingMasterSchema);
    const { showCustomAlert } = useCustomAlertController()
    const { openModal, closeModal } = useModal();
    const [selectedUser, setSelectedUser] = useState(null)
    const { loadingState, setDynamicLoading } = useDynamicLoading()

    useEffect(()=>{
        console.log('row -- - - - ', row);
    },[])

    const handleSubmit = () => {
        var json = row
        Object.assign(json,{userIds : selectedUser?.map((ele)=>ele?.userId)})
        const validationErrors = validate(json);
        
        if (Object.keys(validationErrors).length === 0) {
            setDynamicLoading({addUser : true})
            HitApi(json, updateBuilding).then((res)=>{
                showCustomAlert(res)
                closeModal()
                setDynamicLoading({addUser : false})
            })
        }
        
    }

    const handleChange = (e) => {
        console.log('e', e);
        var json = row
        var tUSerIds = []
        e?.map((ele)=>{
            tUSerIds?.push({userId : ele?.userId})
        })
        setSelectedUser(tUSerIds)

    }

    return (
        <div className='p-10 '>
            <div className='h-80 flex justify-between flex-col'>
                <SearchableMultiSelect name="userIds" label={'Search User'} validate={validate} error={errors} getFieldName={'username'} api={searchUser} callBackJson={{ userId: 'value', label: 'label', id : 'value' }} reduxState={row} onChange={handleChange} />
                <CustomButton type={'submit'} text={'Add User'} onClick={handleSubmit} loading={loadingState?.doc?.addUser} />
            </div>
        </div>
    )
}
