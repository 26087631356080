import React, { useEffect, useState } from 'react'
import SearchableSelect from '../../../../Component/ui/form/select/SearchableSelect'
import CustomInput from '../../../../Component/ui/form/input/custom-input'
import { useDispatch, useSelector } from 'react-redux'
import CustomButton from '../../../../Component/ui/buttons/custom-button'
import useDynamicLoading from '../../../../Hooks/use-dynamic-loading'
import { useModal } from '../../../../shared/modal-views/use-modal'
import { getProductdsFromInbound, searchLocation, searchProduct, searchZone } from '../../../../Constant/Api/Api'
import { setOrderApiJson } from '../../../../Store/Action/order/order-action'
import { HitApi } from '../../../../Store/Action/Api/ApiAction'
import { CompileDynamicDataLoad } from '../../promiss/order-promiss'
import useValidation from '../../../../Hooks/useValidation'
import { validationSchema } from '../../../../Utils/validators/validationSchema'
import { setSearchableSelectData, setSearchableSelectSelectedData } from '../../../../Store/Action/common/searcheable-select/searcheable-select-action'
import { inboundText, outboundText } from '../../../../Constant/Common/common-variable'

export const addProductSchema = {
  productId: validationSchema?.json('Product Field is Required'),
  quantity: validationSchema.json('Quantity Field is Required'),
  zoneIds:validationSchema?.json()?.optional(),
  locationIds:validationSchema?.json()?.optional()
};

export const KeysToDelete = [
  { removeCase: 'add', removeFromSelect: ['productId', 'zoneIds', 'locationIds'], removeFromRedux: [] },
  { removeCase: 'productId', removeFromSelect: ['zoneIds', 'locationIds'], removeFromRedux: ['productId', 'zoneIds', 'locationIds'] },
  { removeCase: 'zoneIds', removeFromSelect: ['locationIds'], removeFromRedux: ['zoneIds', 'locationIds'] },
]

export default function SearchAndAddProduct({ index, mode, zoneId }) {
  const dispatch = useDispatch()
  const reduxOrder = useSelector(state => state.OrderReducer)
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const { loadingState, setDynamicLoading } = useDynamicLoading()
  const { openModal, closeModal } = useModal();
  const [location, setLocation] = useState(null)
  const { errors, validate } = useValidation(addProductSchema);
  const [helperText, setHelperText] = useState(null)

  useEffect(() => {
    console.log('mode', mode, zoneId);
    if (mode === 'edit' && zoneId) {
      console.log('loading dynamic loation',);
      loadDynamicLocation(zoneId)
    }

  }, [])

  const handleSubmit = () => {
    console.log('reduxSelect', reduxSelect?.selected);
    var json = reduxOrder?.apiJson?.productIds?.[index]

    console.log('json handleSubmit', json, 'index', index);

    Object.assign(json, { status: "ORDER_INITIATED" })
    const validationErrors = validate(json);
    console.log(validationErrors);

    console.log('json', reduxOrder?.apiJson);
    if (Object.keys(validationErrors).length === 0) {
      handleClear('add')
      closeModal()
    }
  }

  const handleClose = () => {
    // const json = { ...reduxOrder?.apiJson };
    // const { zoneIds, locationIds, quantity } = json;
    // const productIds = json?.productIds || []; // Safely handle if productIds is undefined
    // const lastIndex = productIds.length - 1;

    // if ([zoneIds, locationIds, quantity].some(arr => !arr?.[lastIndex]) || !productIds?.[lastIndex]?._id) {
    //   [zoneIds, locationIds, quantity, productIds].forEach(arr => arr?.splice(lastIndex, 1));
    // }

    // dispatch(setOrderApiJson(json));
    closeModal();
  };

  const handleProductChange = (e) => {
    let { label, value, ele, _id } = e
    var json = reduxOrder?.apiJson
    json['productIds'][index] = { ...json['productIds'][index], ['productId']: ele };
    dispatch(setOrderApiJson(json))
    if (reduxOrder?.apiJson?.orderType === outboundText) {
      GetQuantityAsHelperText()
    }
  }

  const GetQuantityAsHelperText = () => {
    var totalQuantity = reduxOrder?.apiJson?.productIds?.[index]?.productId?.totalQuantity
    setHelperText('Available Qty : ' + totalQuantity)
  }


  const handleZoneChange = (e) => {
    let { label, value, ele, _id } = e
    var json = reduxOrder?.apiJson
    json['productIds'][index] = { ...json['productIds'][index], ['zoneIds']: ele, };
    dispatch(setOrderApiJson(json))
    loadDynamicLocation(_id)
  
  }

  const handleLocationChange = (e) => {
    let { label, value, ele, _id } = e
    var json = reduxOrder?.apiJson
    json['productIds'][index] = { ...json['productIds'][index], ['locationIds']: ele };
    dispatch(setOrderApiJson(json))
  }

  const handleClear = (name) => {
    var json = reduxOrder?.apiJson?.['productIds'][index]
    var findCase = KeysToDelete?.find(Obj => Obj.removeCase === name)
    var filterItems = reduxSelect?.selected?.filter(Obj => !findCase?.removeFromSelect?.includes(Obj?.name))
    dispatch(setSearchableSelectSelectedData(filterItems))
    console.log('findCase', findCase);

    findCase?.removeFromRedux?.map((ele) => delete json[ele])
  }

  const loadDynamicLocation = (_id) => {
    var json = { page: 1, limit: 500, search: { zoneIds: _id } }
    HitApi(json, searchLocation).then((result) => {
      console.log('result', result);
      if (result?.content?.[0]) {
        CompileDynamicDataLoad(result).then((CompiledData) => {
          setLocation(CompiledData)
        })
      } else {
        setLocation(null)
      }
    })

  }

  const getDynamicProductSearch = () => {
    const tp = reduxOrder?.apiJson?.productIds?.reduce((acc, ele) => {
      if (ele?.productId?._id) {
        acc.push(ele.productId.productName);
      }
      return acc;
    }, []);

    const query = {}
    if (reduxOrder?.apiJson?.orderType === outboundText) {
      // query.orderType = 'INBOUND'
      // query.dispatchFrom = reduxOrder?.apiJson?.dispatchFrom
      query.currentLocation = reduxOrder?.apiJson?.dispatchFrom
      query.tagType = "INVENTORY"
      query.opreationStatus = "ACTIVE"

    }

    // Add productName condition only if tp has valid items
    if (tp?.length) {
      query.productName = { "$nin": tp };
    }

    console.log('query', query);

    return query;
  };

  return (
    <div className='p-10'>
      <div className="space-y-5 lg:space-y-6">
        <SearchableSelect selectionType={'productId'} name={'productId'} label={'Select Product'} validate={validate} error={errors} api={reduxOrder?.apiJson?.orderType === 'INBOUND' ? searchProduct : getProductdsFromInbound} dynamicSearch={getDynamicProductSearch()} getFieldName={'productName'} onChange={handleProductChange} onClear={() => handleClear('productId')} disabled={mode === 'edit'} />
        <CustomInput helperText={helperText} helperTextClass={'text-green-buttonGreen'} inputType={'number'} name={'quantity'} label={'Quantity'} validate={validate} error={errors} reduxState={reduxOrder?.apiJson} setAction={setOrderApiJson} parent={'productIds'} index={index} />
        {reduxOrder?.apiJson?.orderType === inboundText && <SearchableSelect selectionType={'zoneIds'} name={'zoneIds'} label={'Select Zone'} validate={validate} error={errors} api={searchZone} getFieldName={'value'} onChange={handleZoneChange} onClear={() => handleClear('zoneIds')} />}
        {reduxOrder?.apiJson?.productIds?.[index]?.zoneIds?._id && <SearchableSelect selectionType={'locationIds'} name={'locationIds'} label={'Select Location'} validate={validate} error={errors} defaultOptions={location || []} onChange={handleLocationChange} onClear={() => handleClear('locationIds')} />}
        <div className='flex gap-3 justify-end'>
          <CustomButton text={'Cancel'} variant='flat' className={''} onClick={handleClose} />
          <CustomButton type={'submit'} className={''} loading={loadingState?.doc?.addProduct} text={'Add'} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  )
}
