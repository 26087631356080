import React, { useState } from 'react'
import { useModal } from '../../../../shared/modal-views/use-modal';
import useCustomAlertController from '../../../../Hooks/use-custom-alert copy';
import Skeleton from 'react-loading-skeleton';
import { HeaderCell } from '../../../../Component/ui/table';
import TableActions from '../../../../Component/ui/table/TableActions';
import { Text } from 'rizzui';
import { ScreenName } from '../../../../Constant/Screen/Screen';
import DeletePopover from '../../../../shared/delete-popover';
import { HitApi } from '../../../../Store/Action/Api/ApiAction';
import { updateZone } from '../../../../Constant/Api/Api';


export const GetAddedBuildingColumns = (row, ApiHit) => {
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useCustomAlertController()
  const [loadingRows, setLoadingRows] = useState({})

  const handleDelete = async (childRow) => {
    console.log('row - - - ', row);
    var json = row
    var filteredItems = json?.usedBy?.filter((Obj)=>Obj?._id !== childRow?._id)
    Object.assign(json, {usedBy : filteredItems?.map((ele)=>ele?._id)})
    console.log('remove used by json', json);
    const result = await HitApi(json, updateZone);
    if(result){
      showCustomAlert(result)
      if(ApiHit){ApiHit()}
    }
    
  }

  const renderCell = (value, row, content) => (
    loadingRows[row.index] ? <Skeleton /> : content
  );

  return [
    {
      title: <HeaderCell title="#" />,
      dataIndex: 'index',
      key: 'index',
      width: 10,
      render: (value, row, index) => renderCell(value, row, <Text>{index+1 || '---'}</Text>),
    },
    {
      title: <HeaderCell title="Building Name" className="font-extrabold" />,
      dataIndex: 'buildingName',
      key: 'buildingName',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Building No." className="font-extrabold" />,
      dataIndex: 'buildingNo',
      key: 'buildingNo',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Actions" className="font-extrabold" />,
      dataIndex: 'action',
      key: 'action',
      width: 300,
      render: (_, row) => renderCell(null, row, (
        <DeletePopover
          title="Remove Assigned Buidling"
          description="Are you sure you want to remove assigned building?"
          onDelete={() => handleDelete(row)}
        />
      )),
    },
  ];

}
