import { PiBrowserDuotone } from 'react-icons/pi';
import { routes } from '../config/routes';
import UserManagementIcon from '../Constant/Icons/user-management-icon';
import RolePermissionIcon from '../Constant/Icons/role-permission-icon';
import SiteMasterIcon from '../Constant/Icons/site-master-icon';
import BuildingsMasterIcon from '../Constant/Icons/building-master-icon';
import ReaderMasterIcon from '../Constant/Icons/reader-master-icon';
import ProductMasterIcon from '../Constant/Icons/product-master-icon';
import CustomerMasterIcon from '../Constant/Icons/coustumer-master-icon';
import SupplierMasterIcon from '../Constant/Icons/supplier-master-icon';
import GeneralMasterIcon from '../Constant/Icons/general-master-icon';
import MappingMasterIcon from '../Constant/Icons/mapping-master-icon';
import VehicleMasterIcon from '../Constant/Icons/vehicle-master-icon';
import ConfigurationMasterIcon from '../Constant/Icons/configuration-master-icon';
import WebReceivingIcon from '../Constant/Icons/web-receiving-icon';
import LocationMasterIcon from '../Constant/Icons/location-master-icon';
import ZoneMasterIcon from '../Constant/Icons/zone-master-icons';
import TagMasterIcon from '../Constant/Icons/tag-master-icon';
import { RxDashboard } from 'react-icons/rx';

export const menuItems = [
  {
    id: '1',
    name: 'Dashboard',
    title: 'Overview',
    icon: RxDashboard,
    menuItems: [
      { name: 'User Management', href: routes?.panel?.master?.users, icon: UserManagementIcon },
      { name: 'Roles and Permission', href: routes?.panel?.master?.rolesAndPermission, icon: RolePermissionIcon },
      { name: 'Site Master', href: routes?.panel?.master?.siteMaster, icon: SiteMasterIcon },
      { name: 'Buildings Master', href: routes?.panel?.master?.buildingsMaster, icon: BuildingsMasterIcon },
      { name: 'Zone Master', href: routes?.panel?.master?.zomeMaster, icon: ZoneMasterIcon },
      { name: 'Tag Master', href: routes?.panel?.master?.tagMaster, icon: TagMasterIcon },
      { name: 'Location Master', href: routes?.panel?.master?.locationMaster, icon: LocationMasterIcon },
      { name: 'Product Master', href: routes?.panel?.master?.productMaster, icon: ProductMasterIcon },
      { name: 'Customer Master', href: routes?.panel?.master?.customerMaster, icon: CustomerMasterIcon },
      { name: 'Supplier Master', href: routes?.panel?.master?.supplierMaster, icon: SupplierMasterIcon },
      { name: 'Inventory Master', href: routes?.panel?.master?.innventoryMaster, icon: GeneralMasterIcon },
      { name: 'Vehicle Master', href: routes?.panel?.master?.vehicleMaster, icon: VehicleMasterIcon },
      { name: 'Configuration Master', href: routes?.panel?.master?.configurationMaster, icon: ConfigurationMasterIcon },
      { name: 'General Master', href: routes?.panel?.master?.generalMaster, icon: GeneralMasterIcon },
      { name: 'Mapping Master', href: routes?.panel?.master?.mappingMaster, icon: MappingMasterIcon },
    ],
  },
  {
    id: '2',
    name: 'Device Manager',
    title: 'Manage Devices',
    icon: ReaderMasterIcon,
    menuItems: [
      { name: 'Certificates', href: routes?.panel?.deviceManager?.certificates, icon: ReaderMasterIcon },
      { name: 'Brokers', href: routes?.panel?.deviceManager?.broker, icon: ReaderMasterIcon },
      { name: 'Configuration', href: routes?.panel?.deviceManager?.configuration, icon: ReaderMasterIcon },
      { name: 'Mapper', href: routes?.panel?.deviceManager?.mapper, icon: ReaderMasterIcon },
      { name: 'Api Services', href: routes?.panel?.deviceManager?.apiService, icon: ReaderMasterIcon },
      { name: 'Devices', href: routes?.panel?.deviceManager?.devices, icon: ReaderMasterIcon },
    ],
  },
  {
    id: '3',
    name: 'Inbound',
    title: 'Inbound Operations',
    icon: WebReceivingIcon,
    menuItems: [
      // { name: 'Inbound Order Old', href: routes?.panel?.inbond?.inboundOrder, icon: WebReceivingIcon },
      // { name: 'Web Receiving', href: routes?.panel?.inbond?.webReceiving, icon: WebReceivingIcon },
      { name: 'Inbound Order', href: routes?.panel?.order?.inboundOrder, icon: WebReceivingIcon },
    ],
  },
  {
    id: '4',
    name: 'Outbound',
    title: 'Outbound Operations',
    icon: WebReceivingIcon,
    menuItems: [
      { name: 'Outbound Order', href: routes?.panel?.order?.outboundOrder, icon: WebReceivingIcon },
      // { name: 'Web Picking', href: routes?.panel?.outbond?.webPicking, icon: WebReceivingIcon },
    ],
  },
];
