import { Text, Tooltip, ActionIcon } from 'rizzui';
import { HeaderCell } from '../../../Component/ui/table';
import DeletePopover from '../../../shared/delete-popover';
import PencilIcon from '../../../Constant/Icons/pencil';
import AddSiteMaster from '../../../Form/master/site-master/add-site-master';
import {EditScreen} from '../../../shared/edit-screen';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { deleteGeneral, deleteLocation, deleteSite, deleteTag, deleteVehicle } from '../../../Constant/Api/Api';
import AddVehicleMaster from '../../../Form/master/vehicle-master/add-vehcile-master';
import AddLocationMaster from '../../../Form/master/location-master/add-location-master';
import AddTagMaster from '../../../Form/master/tag-master/add-tag-master';

export const getTagMasterColumns = ({ openModal, closeModal }) => [
    
  {
    title: (
      <HeaderCell title="#" />
    ),
    dataIndex: 'index',
    key: 'index',
    width: 10,
    render: (value, row, index) => <Text>{index + 1 || '---'}</Text>,
  },
  {
    title: (
      <HeaderCell title="_id" />
    ),
    dataIndex: '_id',
    key: '_id',
    width: 10,
    render: (value) => <Text>{ value?.substring(value?.length - 5)  || '---'}</Text>,
  },
  {
    title: <HeaderCell title="Tag Type" className={'font-extrabold'} />,
    dataIndex: 'tagType',
    key: 'tagType',
    width: 100,
    render: (value) => (
      <Text className="font-medium text-gray-700">{value || '---'}</Text>
    ),
  },
  {
    title: <HeaderCell title="Tag Info"  className={'font-extrabold'}/>,
    dataIndex: 'tagInfo',
    key: 'tagInfo',
    width: 100,
    render: (value) => (
      <Text className="font-medium text-gray-700">{value || '---'}</Text>
    ),
  },
  {
    title: <HeaderCell title="RFID Tag"  className={'font-extrabold'}/>,
    dataIndex: 'rfidTag',
    key: 'rfidTag',
    width: 100,
    render: (value) => (
      <Text className="font-medium text-gray-700">{value || '---'}</Text>
    ),
  },
  {
    title: <HeaderCell title="Actions" className={'font-extrabold'}/>,
    dataIndex: 'action',
    key: 'action',
    width: 600,
    render: (_, row) => (
      <div className="flex items-center gap-3 pe-4">
        <Tooltip size="sm" content={'Edit Tag Master'} placement="top" color="invert">
          <label>
            <ActionIcon as="span" size="sm" variant="outline" className="hover:text-gray-700" onClick={()=>EditScreen(openModal, closeModal, row, 'Edit Tag Master' , AddTagMaster, 800)}>
              <PencilIcon className="h-4 w-4" />
            </ActionIcon>
          </label>
        </Tooltip>
        <DeletePopover title={`Delete Tag Master`}  description={`Are you sure you want to delete this employee?`} 
          onDelete={() => DeleteItem(row._id)} 
        />
      </div>
    ),
  },
];


export const DeleteItem = (_id) =>{
  var json = {_id:_id}
  HitApi(json, deleteTag).then((Result)=>{
    console.log('Result', Result);
  })
}