import React from 'react';
import { useMedia } from '../../../../../Hooks/use-media';
import { useDispatch } from 'react-redux';
import { Input } from 'rizzui';

export default function CustomInput({
    inputType, type, name, label, onChange, error, placeholder, reduxState,
    setAction, important, disabled, validate, helperText, maxLength, hide, validateField, parent
}) {

    const dispatch = useDispatch();
    const isMedium = useMedia('(max-width: 1200px)', false);

    const value = reduxState?.[name] || '';

    // Function to validate each octet of the IP address
    const isValidIPPart = (part) => {
        const num = Number(part);
        return num >= 0 && num <= 255;
    };

    // Function to validate full IP address format
    const isValidIP = (ip) => {
        const ipParts = ip.split('.');
        if (ipParts.length !== 4) return false;
        return ipParts.every(isValidIPPart);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (inputType === 'ip') {
            // Allow user to enter partial IP address and edit anywhere in the input
            const validPartialIP = value.match(/^(\d{1,3}(\.\d{0,3}){0,3})?$/);
            if (validPartialIP || value === '') {
                updateReduxState(name, value);
            }
        } else if (inputType === 'port') {
            const port = Number(value);
            if (port >= 0 && port <= 65535) {
                updateReduxState(name, value);
            }
        } else {
            updateReduxState(name, value);
        }

        if (onChange) onChange(e);
        if (validate) validate([name, value]);
    };

    const updateReduxState = (name, value) => {
        if (parent) {
            parent.userEntry = value;
        } else {
            let updatedJson = { ...reduxState };
            updatedJson[name] = value;
            dispatch(setAction(updatedJson));
        }
    };

    // Allow numbers, periods, backspace, and Ctrl+A for IP input
    const handleIpKeyDown = (event) => {
        const charCode = event.which ? event.which : event.keyCode;

        // Check if Ctrl (or Command) + A is pressed (for select all)
        if ((event.ctrlKey || event.metaKey) && charCode === 65) {
            return; // Allow Ctrl + A (select all)
        }

        // Allow numbers (48-57), period (46), and backspace (8)
        if ((charCode < 48 || charCode > 57) && charCode !== 190 && charCode !== 8) {
            event.preventDefault();
        }
    };

    // Restrict to numbers for port input
    const handlePortKeyDown = (event) => {
        const charCode = event.which ? event.which : event.keyCode;
         // Check if Ctrl (or Command) + A is pressed (for select all)
         if ((event.ctrlKey || event.metaKey) && charCode === 65) {
            return; // Allow Ctrl + A (select all)
        }
        // Allow only numbers (48-57) and backspace (8)
        if ((charCode < 48 || charCode > 57) && charCode !== 8) {
            event.preventDefault();
        }
    };

    // Handle text input key down (letters, space, special characters)
    const handleTextKeyDown = (event) => {
        const charCode = event.which ? event.which : event.keyCode;
        if (
            !(charCode >= 65 && charCode <= 90) &&  // Uppercase letters
            !(charCode >= 97 && charCode <= 122) && // Lowercase letters
            !(charCode === 32) &&                   // Space
            !(charCode >= 33 && charCode <= 47) &&  // Special characters: ! " # $ % & ' ( ) * + , - . /
            !(charCode >= 58 && charCode <= 64) &&  // Special characters: : ; < = > ? @
            !(charCode >= 91 && charCode <= 96) &&  // Special characters: [ \ ] ^ _ `
            !(charCode >= 123 && charCode <= 126) && // Special characters: { | } ~
            !(charCode === 8)   // Special characters: backspace
        ) {
            event.preventDefault();
        }
    };

    // Handle number input (only digits)
    const handleNumberKeyDown = (event) => {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    };

    // Handle any input
    const handleAnyKeyDown = (event) => {
        // Allow any input, including letters, numbers, and special characters
    };

    // Determine the appropriate handler based on input type
    const handleKeyDown =
        inputType === 'ip' ? handleIpKeyDown :
            inputType === 'port' ? handlePortKeyDown :
                inputType === 'number' ? handleNumberKeyDown :
                    inputType === 'text' ? handleTextKeyDown : handleAnyKeyDown;

    const GetValue = () => {
        return parent ? parent?.userEntry : value;
    };

    return (
        <>
            {!hide && (
                <div className="mb-1">
                    <label className="block font-bold mb-1">{label}{important === false ? '(Optional)' : ''}</label>
                    <Input
                        size='sm'
                        type={type || 'text'}
                        maxLength={maxLength}
                        name={name}
                        value={GetValue()}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        placeholder={placeholder || `Enter ${label} ${important === false ? '(Optional)' : ''}`}
                        className={`w-full py-1.5 text-lg rounded-lg disabled:bg-gray-200 `}
                        error={error?.[name]}
                        disabled={disabled}
                    />
                    {disabled ? <span className='text-red-500 text-xs tracking-wide'>This field cannot be edited</span> : null}
                    {helperText ? <span className='text-red-500 font-bold text-xs tracking-wide'>{helperText}</span> : null}
                </div>
            )}
        </>
    );
}
