import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../../../shared/modal-views/use-modal";
import useAlertController from "../../../Hooks/use-alert-controller";
import { useEffect, useState } from "react";
import { HitApi } from "../../../Store/Action/Api/ApiAction";
import { searchVehicle } from "../../../Constant/Api/Api";
import { CompileVehicleMaster } from './promise/vehicle-master-promise'
import { setVehicleMasterData } from '../../../Store/Action/master/vehicle-master/vehicle-master-action'
import { setPagination } from "../../../Store/Action/Pagination/PaginationAction";
import { useMemo } from "react";
import { GetVehicleMasterColumns } from './vehicle-column'
import { useColumn } from "../../../Hooks/use-column";
import PageHeader from "../../../shared/page-header";
import { ScreenName } from "../../../Constant/Screen/Screen";
import AddVehicleMaster from "../../../Form/master/vehicle-master/add-vehcile-master";
import ControlledTable from "../../../Component/ui/table/custom-table";
import { TableClass } from "../../../Constant/Classes/Classes";





function Vehiclemaster() {
  const dispatch = useDispatch()
  const reduxVehicle = useSelector(state => state.VehicleMasterReducer)
  const reduxPagination = useSelector(state => state.PaginationReducer);
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useAlertController();
  const [loading, setLoading] = useState(false)

  const loadData = (type) => {
    var json = reduxVehicle?.searchJson
    if (type === 'init') {
      Object.assign(json, { page: 1, limit: reduxPagination?.doc?.limit });
    } else {
      Object.assign(json, { page: reduxPagination?.doc?.number, limit: reduxPagination?.doc?.limit });
    }
    setLoading(true);

    HitApi(json, searchVehicle).then((result) => {
      if (result?.success !== false) {
        CompileVehicleMaster(result).then((compiledData) => {
          dispatch(setVehicleMasterData(compiledData));
          dispatch(setPagination({
            limit: json?.limit,
            totalPages: compiledData?.totalPages,
            number: compiledData?.number,
            totalElements: compiledData?.totalElements,
          }));
        });
      } else {
        dispatch(setVehicleMasterData([]));
      }
      setLoading(false);
    });
  }
  const columns = useMemo(() => GetVehicleMasterColumns(openModal, closeModal, loadData, showCustomAlert))
  const { visibleColumns } = useColumn(columns);

  useEffect(() => {
    if (reduxVehicle?.doc === null) {
      loadData('init')
    }
  }, [])

  return (
    <div>
      <PageHeader screen={ScreenName?.vehicleMaster} metaTitle={'Vehicle Master'} btnText={'Add Vehicle'} children={<AddVehicleMaster closeModal={closeModal} ApiHit={loadData} />} title={'Add Vehicle'} customSize={800} />
      <ControlledTable
        screen={ScreenName?.vehicleMaster}
        variant="modern"
        isLoading={loading}
        showLoadingText={true}
        data={reduxVehicle?.doc?.content}
        columns={visibleColumns}
        className={TableClass}
        json={reduxVehicle?.searchJson}
        setAction={setVehicleMasterData}
        ApiHit={loadData}
      />
    </div>
  )
}
export default Vehiclemaster