import { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { HeaderCell } from "../../../Component/ui/table";
import { Text } from "rizzui";
import { HitApi } from "../../../Store/Action/Api/ApiAction";
import { getDocCount, searchTag, searchTagHistory } from "../../../Constant/Api/Api";

export const GetProductColumn = (parentRow) => {
    const [loadingRows, setLoadingRows] = useState({});

    // Function to render cell content with skeleton if loading
    const renderCell = (value, row, content) => (
        loadingRows[row.index] ? <Skeleton /> : content
    );

    // Static columns that remain the same
    const staticColumns = [
        {
            title: <HeaderCell title="#" />,
            dataIndex: 'index',
            key: 'index',
            width: 10,
            render: (value, row, index) => renderCell(value, row, <Text>{index + 1 || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Product Code" />,
            dataIndex: 'productCode',
            key: 'productCode',
            width: 50,
            render: (value, row, index) => renderCell(value, row, <Text>{row?.productId?.productCode || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Product Name" />,
            dataIndex: 'productName',
            key: 'productName',
            width: 50,
            render: (value, row, index) => renderCell(value, row, <Text>{row?.productId?.productName || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Product Grade" />,
            dataIndex: 'grade',
            key: 'grade',
            width: 50,
            render: (value, row, index) => renderCell(value, row, <Text>{row?.productId?.grade || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Product Qty" />,
            dataIndex: 'quantity',
            key: 'quantity',
            width: 50,
            render: (value, row, index) => renderCell(value, row, <Text>{row?.quantity}</Text>),
        }
    ];

    // Dynamic columns based on inventory data
    const dynamicColumns = generateDynamicColumns(renderCell, parentRow);

    // Merge static and dynamic columns
    return [...staticColumns, ...dynamicColumns];
};

// Function to generate dynamic columns for Picked Qty and Pending Qty
const generateDynamicColumns = (renderCell, parentRow) => {
    return [
        {
            title: <HeaderCell title="Picked Qty" />,
            dataIndex: 'pickedQty',
            key: 'pickedQty',
            width: 50,
            render: (value, row, index) => renderCell(value, row, <InventoryDisplay row={row} parentRow={parentRow} />),
        },
        {
            title: <HeaderCell title="Pending Qty" />,
            dataIndex: 'pendingQty',
            key: 'pendingQty',
            width: 50,
            render: (value, row, index) => renderCell(value, row, <Text>{row?.pendingQty}</Text>),
        }
    ];
};

// New component to handle inventory fetching and displaying pickedQty and pendingQty
const InventoryDisplay = ({ row, parentRow }) => {
    const [inventoryData, setInventoryData] = useState({
        pickedQty: row.pickedQty || 0,
        pendingQty: row.pendingQty || row.quantity || 0,
        loading: true,
    });

    useEffect(() => {
        if (row?.productId?._id) {
            const json = { page: 1, limit: 1, search: { product_id: row?.productId?._id, orderId:parentRow?._id, opreationStatus : parentRow?.orderStatus }, collectionName: "tagCollectiontrail" };
            HitApi(json, getDocCount)
                .then((result) => {
                    if (result) {
                        const pickedQty = result?.count || 0;
                        const pendingQty = row?.quantity - pickedQty;

                        // Update inventory data state
                        setInventoryData({
                            pickedQty,
                            pendingQty,
                            loading: false,
                        });

                        // Also update the row's pendingQty so it displays correctly in the Pending Qty column
                        row.pickedQty = pickedQty;
                        row.pendingQty = pendingQty;
                    }
                })
                .catch(() => {
                    // Handle API error
                    setInventoryData({ ...inventoryData, loading: false });
                });
        }
    }, [row]);

    if (inventoryData.loading) {
        return <Skeleton />;
    }

    return (
        <Text>
            {inventoryData.pickedQty}
        </Text>
    );
};
