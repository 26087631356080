import React, { useState } from 'react'
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { updateZone } from '../../../Constant/Api/Api';
import { HeaderCell } from '../../../Component/ui/table';
import { Text } from 'rizzui';
import Skeleton from 'react-loading-skeleton';
import TableActions from '../../../Component/ui/table/TableActions';
import { EditScreen } from '../../../shared/edit-screen';
import AddZoneMaster from '../../../Form/master/zone-master/add-zone-master';
import { ScreenName } from '../../../Constant/Screen/Screen';
import { deactiveText } from '../../../Constant/Common/common-variable';
import useCustomAlertController from '../../../Hooks/use-custom-alert copy';
import { useModal } from '../../../shared/modal-views/use-modal';
import ZoneBuilding from './zone-building/zone-building';
import BuildingSearchAndAdd from '../../../Form/master/zone-master/building-search-and-add';

export const GetZoneMasterColumns = (ApiHit) => {
  const { openModal, closeModal } = useModal();
  const { showCustomAlert } = useCustomAlertController()
  const [loadingRows, setLoadingRows] = useState({})

  const handleDelete = async (row) => {
    setLoadingRows((prev) => ({ ...prev, [row.index]: true }));
    Object.assign(row, { status: deactiveText })
    try {
      const result = await HitApi(row, updateZone);
      showCustomAlert(result)
    } catch (err) {

    } finally {
      setLoadingRows((prev) => ({ ...prev, [row.index]: false }));
    }

  };

  const renderCell = (value, row, content) => (
    loadingRows[row.index] ? <Skeleton /> : content
  );

  return [
    {
      title: <HeaderCell title="#" />,
      dataIndex: 'index',
      key: 'index',
      width: 10,
      render: (value, row, index) => renderCell(value, row, <Text>{value || '---'}</Text>),
    },
    {
      title: <HeaderCell title="Zone Name" className="font-extrabold" />,
      dataIndex: 'value',
      key: 'value',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Buildings Added" className={'font-extrabold'} />,
      dataIndex: 'usedBy',
      key: 'usedBy',
      width: 100,
      render: (usedBy, row) => (
        <Text className="font-medium text-gray-700 flex justify-between">
          <label>{usedBy?.length || 0}</label>
          <label className='cursor-pointer' onClick={()=>HandleShowBuildingDetails(openModal, closeModal, row)}>Show</label>
        </Text>
      ),
    },
    {
      title: <HeaderCell title="Tag Ids" className={'font-extrabold'} />,
      dataIndex: 'tagIds',
      key: 'tagIds',
      width: 100,
      render: (value) => (
        <Text className="font-medium text-gray-700">
          {value}
        </Text>
      ),
    },

    {
      title: <HeaderCell title="Status" className="font-extrabold" />,
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },

    {
      title: <HeaderCell title="Actions" className="font-extrabold" />,
      dataIndex: 'action',
      key: 'action',
      width: 300,
      render: (_, row) => renderCell(null, row, (
        <TableActions
          screen={ScreenName?.zoneMaster}
          row={row}
          onEdit={(rowData) => EditScreen(openModal, closeModal, rowData, 'Edit Zone Master', AddZoneMaster, 400, ApiHit)}
          onDelete={(rowData) => handleDelete(rowData)}
          onaddSection={(rowData) => EditScreen(openModal, closeModal, rowData, 'Add Building', BuildingSearchAndAdd, 800, ApiHit)}
          checkKeys={['buildingIds', 'usedBy']}
          enableAdd={true}
        />
      )),
    },
  ];

}


export const HandleShowBuildingDetails = (openModal, closeModal, rowData, ApiHit) =>{
  EditScreen(openModal, closeModal, rowData, 'Added Building Details', ZoneBuilding, 800, ApiHit)
}