export const _id = '_id'
export const deactiveText = 'deactive'
export const statusText = 'status'
export const location = 'location'
export const weighing_scale = 'weighing_scale'
export const reader = 'reader'
export const vehicle = 'vehicle'
export const building = 'building'

// Roles and Permission
export const userManagementMaster = 'user management master'
export const rolesAndPermissionMaster = 'roles and permission master'
export const siteMaster = 'site master'
export const buildingsMaster = 'buildings master'
export const zoneMaster = 'zone master'
export const tagMaster = 'tag master'
export const locationMaster = 'location master'
export const productMaster = 'product master'
export const customerMaster = 'customer master'
export const supplierMaster = 'supplier master'
export const inventoryMaster = 'inventory master'
export const vehicleMaster = 'vehicle master'
export const configuraitonMaster = 'configuration master'
export const generalMaster = 'general master'
export const mappingMaster = 'mapping master'

// Device
export const readerMaster = 'reader master'
export const readerReplacementMaster = 'reader replacement master'
export const readerHealthMaster = 'reader health master'
export const readerBuildingMappingMaster = 'reader building mapping master'
export const weighingScaleMaster = 'weighing scale master'

// Device Manager
export const deviceManager = 'Device Manager'
export const certificateManager = 'certificate master'
export const brokerManager = 'broker master'
export const configurationDeviceManager = 'configuration'
export const mapperManager = 'mapper'
export const apiServicesManager = 'api services master'
export const devicesManager = 'devices'

// Hand held device
export const handHeldManager = 'Handheld Manager'
export const outboundOrder = 'Outbond Orders'
export const inboundOrder = 'Inbound Orders'
export const holdOrder = 'Hold'
export const unHoldOrder = 'Un Hold'
export const replaceOrder = 'Replace'
export const consumeOrder = 'Consume'
export const mappingOrder = 'Mapping'
export const cycleCount = 'Cycle Count'
export const moveOrder = 'Move'
export const gStatusChange = 'General Status Change'
export const recheckOrder = 'Recheck Order'


// inbound order
export const inbound = 'inbound'
export const panelInboundOrder = 'inbound order'

export const outboundText = 'OUTBOUND'
export const inboundText = 'INBOUND'


// Order Status
export const ORDER_PICKING = 'ORDER_PICKING'
export const ORDER_PICKED = 'ORDER_PICKED'
export const HOLD = 'HOLD'
export const CANCELLED = 'CANCELLED'

export const DISPATCHED = 'DISPATCHED'
export const IN_TRANSIT = 'IN_TRANSIT'
export const IN_BUILDING = 'IN_BUILDING'

export const INVENTORY = "INVENTORY"
export const ACTIVEINVENTORY = "ACTIVEINVENTORY"
export const INACTIVEINVENTORY = "INACTIVEINVENTORY"


export const READER = "READERS"
export const ORDER = "ORDERS"
export const INBOUNDORDER = "INBOUNDORDER"
export const OUTBOUNDORDER= "OUTBOUNDORDER"
export const INCOMINGORDER= "INCOMINGORDER"

export const ACTIVE = "ACTIVE"
export const INACTIVE = { "$not": { "$regex": "ACTIVE" }}



export const inventoryQuery = (buildingId, opreationStatus, type) => {

    var json = {
        page: 1,
        limit: 10,
        search: {
            currentLocation: buildingId,
            opreationStatus: opreationStatus,
            tagType: type
        }
    }

    return json
}


export const globalSearch = (page,limit,search) => {

    var json = {
        page: page,
        limit: limit,
        search: search
    }

    return json
}






// export const userManagementMaster = 'user management master'


// export const userManagementMaster = 'user management master'
// export const userManagementMaster = 'user management master'
// export const userManagementMaster = 'user management master'
// export const userManagementMaster = 'user management master'
// export const userManagementMaster = 'user management master'