import React, { useEffect, useState } from 'react'
import CustomInput from '../../../Component/ui/form/input/custom-input'
import { useDispatch, useSelector } from 'react-redux';
import useValidation from '../../../Hooks/useValidation';
import SearchableSelect from '../../../Component/ui/form/select/SearchableSelect';
import CustomButton from '../../../Component/ui/buttons/custom-button';
import { Country, State, City } from 'country-state-city';
import { setSearchableSelectSelectedData } from '../../../Store/Action/common/searcheable-select/searcheable-select-action';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import useAlertController from '../../../Hooks/use-alert-controller';
import { useNavigate } from 'react-router-dom';
import { addSupplierSchema } from '../../../Utils/validators/master/supplier-master/add-supplier.schema';
import { addSupplier, searchGeneral, searchSite, searchSupplier, updateSupplier } from '../../../Constant/Api/Api';
import { setSupplierApiJson, setSupplierData } from '../../../Store/Action/master/supplier-master/supplier-master-action';



function AddSupplierMaster() {
    const { showCustomAlert } = useAlertController();
    const reduxSupplier = useSelector(state => state.SupplierMasterReducer);
    const reduxSelect = useSelector(state => state.SearchableSelectReducer);
    const { errors, validate } = useValidation(addSupplierSchema);
    const reduxUser = useSelector(state => state.UserReducer);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const url = window.location.pathname
    const urlId = url.split('/')[4]
    const [countries, setCountries] = useState(reduxSupplier?.apiJson?.supplierCountry || null);
    const [states, setStates] = useState(null);
    const [cities, setCities] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (urlId && !reduxSupplier?.apiJson?.supplierCountry && !reduxSupplier?.apiJson?.supplierState && !reduxSupplier?.apiJson?.supplierCity) {
            loadDefault()
        }
        if (countries === null) {
            const allCountries = Country.getAllCountries().map(country => ({
                label: country.name,
                value: country.name
            }));
            setCountries(allCountries);
        }
        if (reduxSupplier?.apiJson?.supplierCountry && states === null) {
            const selectedCountryName = reduxSupplier?.apiJson?.supplierCountry;
            const selectedCountry = Country.getAllCountries().find(country => country.name === selectedCountryName)?.isoCode;
            const states = State.getStatesOfCountry(selectedCountry).map(state => ({
                label: state.name,
                value: state.name
            }));
            setStates(states);
        }
        if (reduxSupplier?.apiJson?.supplierState && cities === null) {
            const countryISOCode = Country.getAllCountries().find(country => country.name === reduxSupplier?.apiJson?.supplierCountry)?.isoCode;
            const stateISOCode = State.getStatesOfCountry(countryISOCode).find(state => state.name === reduxSupplier?.apiJson?.supplierState)?.isoCode;
            const cities = City.getCitiesOfState(countryISOCode, stateISOCode).map(city => ({
                label: city.name,
                value: city.name
            }));
            setCities(cities);
        }
    }, [reduxSupplier, cities, states])

    const loadDefault = () => {
        var json = {
            page: 1,
            limit: 1,
            search: {
                _id: urlId
            }
        }
        HitApi(json, searchSupplier).then((result) => {

            var oldRedux = reduxSupplier?.apiJson

            if (result?.content[0]) {
                var json = [
                    { name: 'supplierGroup', value: result?.content[0]?.supplierGroup, label: result?.content[0]?.supplierGroup },
                    { name: 'supplierType', value: result?.content[0]?.supplierType, label: result?.content[0]?.supplierType },
                    { name: 'supplierCountry', value: result?.content[0]?.supplierCountry, label: result?.content[0]?.supplierCountry },
                    { name: 'supplierState', value: result?.content[0]?.supplierState, label: result?.content[0]?.supplierState },
                    { name: 'supplierCity', value: result?.content[0]?.supplierCity, label: result?.content[0]?.supplierCity },
                    { name: 'supplierStatus', value: result?.content[0]?.supplierStatus, label: result?.content[0]?.supplierStatus },
                    { name: 'siteIds', value: result?.content[0]?.siteIds?.siteName, label: result?.content[0]?.siteIds?.siteName }
                ]
                dispatch(setSearchableSelectSelectedData(json))
            }

            Object.assign(result?.content[0], { siteIds: result?.content[0]?.siteIds?._id })
            var newRedux = { ...oldRedux, ...result?.content[0] }
            dispatch(setSupplierApiJson(newRedux));
        })
    }

    const handleOnChange = (e, name) => {
        if (name === 'supplierCountry' && reduxSupplier?.apiJson?.supplierState) {

            var oldData = reduxSupplier?.apiJson

            oldData.supplierCountry = e.value

            var selectedData = [];
            var oldSelectedData = reduxSelect?.selected || [];

            if (reduxSupplier?.apiJson?.supplierCity) {
                delete oldData.supplierCity;
                delete oldData.supplierState
                Promise.all(
                    oldSelectedData.map((ele, i) => {
                        return new Promise((resolve) => {
                            if (ele?.name !== 'supplierState' && ele?.name !== 'supplierCity') {
                                if (ele.name === 'supplierCountry') {
                                    ele.name = name
                                    ele.value = e.value
                                    ele.label = e.value
                                }
                                selectedData.push(ele);
                            }
                            resolve();
                        });
                    })
                ).then(() => {
                    setCities(null)
                    setStates(null)
                    dispatch(setSearchableSelectSelectedData(selectedData));
                });
            } else {
                delete oldData.supplierState
                Promise.all(
                    oldSelectedData.map((ele, i) => {
                        return new Promise((resolve) => {
                            if (ele?.name !== 'supplierState') {
                                if (ele.name === 'supplierCountry') {
                                    ele.name = name
                                    ele.value = e.value
                                    ele.label = e.value
                                }
                                selectedData.push(ele);
                            }
                            resolve();
                        });
                    })
                ).then(() => {
                    setStates(null)
                    dispatch(setSearchableSelectSelectedData(selectedData));
                });
            }

            console.log('oldData', oldData);


            dispatch(setSupplierApiJson(oldData));
        } else {
            const { _id, value } = e;
            const newJson = { [name]: name === 'siteIds' ? _id : value };
            const updatedJson = { ...reduxSupplier?.apiJson, ...newJson };
            dispatch(setSupplierApiJson(updatedJson));
        }
    }
    const handleSubmit = (e) => {
        var json = reduxSupplier?.apiJson;
        const validationErrors = validate(json);
        if (Object.keys(validationErrors).length === 0) {
            setLoading(true);
            const apiCall = urlId ? updateSupplier : addSupplier;
            const updatedJson = { ...json, _id: urlId, status: json?.status || 'Active', siteIds: reduxSupplier?.apiJson?.siteIds?._id ? reduxSupplier?.apiJson?.siteIds?._id : reduxSupplier?.apiJson?.siteIds }
            HitApi(updatedJson, apiCall).then((result) => {
                setLoading(false);
                if (result?.status === 200 || result?.status === 201) {
                    dispatch(setSupplierData(null))
                    dispatch(setSupplierApiJson({}))
                    dispatch(setSearchableSelectSelectedData(null))
                    showCustomAlert({
                        type: 'success',
                        title: 'Success!',
                        message: result?.message
                    });
                    navigate('/master/supplier')
                } else if (result?.status === 409) {
                    showCustomAlert({
                        type: 'error',
                        title: 'Error',
                        message: result?.error?.message
                    });
                } else {
                    alert(result.message);
                }
            });
        }
    };

    const handleClear = (key) => {
        var oldJson = { ...reduxSupplier?.apiJson }
        delete oldJson[key]
        dispatch(setSupplierApiJson(oldJson))
    };



    return (
        <div className='p-10 rounded-xl bg-white mt-10' >
            <div className="space-y-5 lg:space-y-6">
                <div className='grid grid-cols-4 gap-4 '>
                    <CustomInput inputType={'text'} important={true} name="supplierName" label="Supplier Name" value={reduxSupplier?.apiJson?.supplierName} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                    <CustomInput important={true} name="supplierCode" label="Supplier Code" value={reduxSupplier?.apiJson?.supplierCode} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                    <SearchableSelect searchable validate={validate} name="supplierGroup" label="Supplier Group" api={searchGeneral} dynamicSearch={{ 'usedBy': 'supplierGroup' }} getFieldName={'value'} value={reduxUser?.apiJson?.roleName} error={errors} reduxState={reduxUser?.apiJson} setAction={setSupplierApiJson} onChange={(e) => handleOnChange(e, 'supplierGroup')} onClear={() => handleClear('supplierGroup')} />
                    <CustomInput important={true} name="supplierContactEmail" label="Supplier Email" value={reduxSupplier?.apiJson?.supplierContactEmail} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                    <SearchableSelect searchable validate={validate} name="supplierType" label="Supplier Type" api={searchGeneral} dynamicSearch={{ 'usedBy': 'supplierType' }} getFieldName={'value'} value={reduxUser?.apiJson?.roleName} error={errors} reduxState={reduxUser?.apiJson} setAction={setSupplierApiJson} onChange={(e) => handleOnChange(e, 'supplierType')} onClear={() => handleClear('supplierType')} />
                    <CustomInput important={true} name="supplierAddress1" label="Supplier Add1" value={reduxSupplier?.apiJson?.supplierAddress1} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                    <CustomInput important={true} name="supplierAddress2" label="Supplier Add2" value={reduxSupplier?.apiJson?.supplierAddress2} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                    <CustomInput important={true} name="supplierLandmark" label="Supplier Landmark" value={reduxSupplier?.apiJson?.supplierLandmark} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                    <SearchableSelect searchable name="supplierCountry" label="Supplier Country" defaultOptions={countries} value={reduxSupplier?.apiJson?.supplierCountry} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} onChange={(e) => handleOnChange(e, 'supplierCountry')} clearable={false}/>
                    <SearchableSelect searchable name="supplierState" label="Supplier State" defaultOptions={states} value={reduxSupplier?.apiJson?.supplierState} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} onChange={(e) => handleOnChange(e, 'supplierState')} clearable={false} />
                    <SearchableSelect searchable name="supplierCity" label="Supplier City" defaultOptions={cities} value={reduxSupplier?.apiJson?.supplierCity} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} onChange={(e) => handleOnChange(e, 'supplierCity')} clearable={false}/>
                    <CustomInput important={true} type={"number"} maxLength={6} name="supplierPostCode" label="Supplier PostCode" value={reduxSupplier?.apiJson?.supplierPostCode} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                    <CustomInput important={true} name="supplierGst" label="Supplier Gst" value={reduxSupplier?.apiJson?.supplierGst} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                    <CustomInput important={true} type={"number"} name="supplierContactPhone" label="Supplier Contact" value={reduxSupplier?.apiJson?.supplierContactPhone} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                    <CustomInput important={true} name="supplierPan" label="Supplier PAN" value={reduxSupplier?.apiJson?.supplierPan} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                    <CustomInput important={true} name="supplierVat" label="Supplier VAT" value={reduxSupplier?.apiJson?.supplierVat} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                    <CustomInput important={true} name="supplierTan" label="Supplier TAN" value={reduxSupplier?.apiJson?.supplierTan} error={errors} reduxState={reduxSupplier?.apiJson} setAction={setSupplierApiJson} validate={validate} />
                    <SearchableSelect name={'supplierStatus'} label="Supplier Status" placeholder={'Supplier Status'} type={'filter'} api={searchGeneral} dynamicSearch={{ 'usedBy': 'status' }} getFieldName={'value'} onChange={(e) => handleOnChange(e, 'supplierStatus')} useCustomDisplay={true} validate={validate} error={errors} onClear={(e) => handleClear('supplierStatus')} />
                    <SearchableSelect searchable name="siteIds" label="Site" api={searchSite} getFieldName={'siteName'} error={errors} onChange={(e) => handleOnChange(e, 'siteIds')} validate={validate} onClear={(e) => handleClear('siteIds')} />
                </div>
                <div className='flex gap-3 justify-end'>
                    <CustomButton text={'Back'} variant='flat' onClick={() => { window.location.pathname = 'master/supplier' }} />
                    <CustomButton type={'submit'} text={urlId ? 'Update' : 'Submit'} loading={loading} onClick={handleSubmit} />
                </div>
            </div>
        </div >
    )
}

export default AddSupplierMaster