'use client';

import { Fragment, useEffect, useRef, useState } from 'react';
import {
  ActionIcon,
  Empty,
  SearchNotFoundIcon,
  Button,
  Title,
  Input,
  cn,
} from 'rizzui';
import {
  PiFileTextDuotone,
  PiMagnifyingGlassBold,
  PiXBold,
} from 'react-icons/pi';
import { NavLink } from 'react-router-dom';
import { pageLinks } from './page-links.data';
import { menuItems } from '../../Layout/menu-items';
import { getAuthenticatedUserWithRoles } from '../../Storage/Storage';

export default function SearchList({ onClose }) {
  const inputRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [allowedList, setAllowedList] = useState([])

  let menuItemsFiltered = allowedList;
  let user = getAuthenticatedUserWithRoles()  

  useEffect(() => {
    

    if (inputRef?.current) {
      // @ts-ignore
      inputRef.current.focus();
    }
    if(allowedList?.length===0){handleListLoad()}
    return () => {
      inputRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedList]);

  const handleListLoad = () =>{
    let td = []
    user?.roleObject?.permission?.map((ele)=>{
      td?.push({name : ele?.value})
      if(ele?.child){
        ele?.child?.map((childEle)=>{
          td?.push({name : childEle?.value})
        })
      }
    })
    let tp=[]
    if(td?.length>0){
      pageLinks?.map((ele)=>{
        var findItem = td?.find(Obj=>Obj?.name === ele?.name?.toLowerCase())
        if(findItem){
          tp?.push({name:ele?.name, href : ele?.href})
        }
      })
    }

    console.log('tp = = = ', tp);
    setAllowedList(tp)
  }

  if (searchText?.length > 0) {
    menuItemsFiltered = pageLinks?.filter((item) => {
      const label = item.name;
      return (
        label.match(searchText?.toLowerCase()) ||
        (label.toLowerCase().match(searchText.toLowerCase()) && label)
      );
    });
  }

  return (
    <>
      <div className="flex items-center px-5 py-4">
        <Input
          variant="flat"
          value={searchText}
          ref={inputRef}
          onChange={(e) => setSearchText(() => e.target.value)}
          placeholder="Search pages here"
          className="flex-1"
          prefix={
            <PiMagnifyingGlassBold className="h-[18px] w-[18px] text-gray-600" />
          }
          suffix={
            searchText && (
              <Button
                size="sm"
                variant="text"
                className="h-auto w-auto px-0"
                onClick={(e) => {
                  e.preventDefault();
                  setSearchText(() => '');
                }}
              >
                Clear
              </Button>
            )
          }
        />
        <ActionIcon
          variant="text"
          size="sm"
          className="ms-3 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <PiXBold className="h-5 w-5" />
        </ActionIcon>
      </div>

      <div className="custom-scrollbar max-h-[60vh] overflow-y-auto border-t border-gray-300 px-2 py-4">
        <>
          {menuItemsFiltered.length === 0 ? (
            <Empty
              className="scale-75"
              image={<SearchNotFoundIcon />}
              text="No Result Found"
              textClassName="text-xl"
            />
          ) : null}
        </>

        {menuItemsFiltered.map((item, index) => {
          return (
            <Fragment key={item.name + '-' + index}>
              {item?.href ? (
                <NavLink
                  to={item?.href}
                  className="relative my-0.5 flex items-center rounded-lg px-3 py-2 text-sm hover:bg-gray-100 focus:outline-none focus-visible:bg-gray-100 dark:hover:bg-gray-50/50 dark:hover:backdrop-blur-lg"
                >
                  <span className="inline-flex items-center justify-center rounded-md border border-gray-300 p-2 text-gray-500">
                    <PiFileTextDuotone className="h-5 w-5" />
                  </span>

                  <span className="ms-3 grid gap-0.5">
                    <span className="font-medium capitalize text-gray-900 dark:text-gray-700">
                      {item.name}
                    </span>
                    <span className="text-gray-500">
                      {item?.href}
                    </span>
                  </span>
                </NavLink>
              ) : (
                <Title
                  as="h6"
                  className={cn(
                    'mb-1 px-3 text-xs font-semibold uppercase tracking-widest text-gray-500 dark:text-gray-500',
                    index !== 0 && 'mt-6 4xl:mt-7'
                  )}
                >
                  {item.name}
                </Title>
              )}
            </Fragment>
          );
        })}
      </div>
    </>
  );
}
