import React, { useEffect, useMemo, useState } from 'react'
import PageHeader from '../../../shared/page-header';
import { routes } from '../../../config/routes';
import { useDispatch, useSelector } from 'react-redux';
import { TableClass } from '../../../Constant/Classes/Classes';
import { useModal } from '../../../shared/modal-views/use-modal';
import { getCertificatesColumns } from './add-certificate/certificates-column';
import { useColumn } from '../../../Hooks/use-column';
import { searchCertificate } from '../../../Constant/Api/Api';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { CompileCertificates } from './promise/certificate-promise';
import { setCertificatesData } from './store/action/certificate-action';
import useDynamicLoading from '../../../Hooks/use-dynamic-loading';
import ControlledTable from '../../../Component/ui/table/custom-table';




function Certificates() {
  const reduxCertificates = useSelector((state) => state.CertificatesReducer);
  const { openModal, closeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const {loadingState, setDynamicLoading} = useDynamicLoading()


  useEffect(() => {
    if (reduxCertificates?.doc === null) {
      loadData();
    }
  }, [reduxCertificates]);

  const loadData = () => {
    const json = reduxCertificates?.searchJson;
    setDynamicLoading({certificates:true})
    HitApi(json, searchCertificate).then((result) => {
      if (result) {

        CompileCertificates(result).then((CompiledData) => {
          dispatch(setCertificatesData(CompiledData))
          setDynamicLoading({certificates:false})
        });
      }
    });
  };

  const columns = useMemo(() => getCertificatesColumns({ loading, setLoading, loadData }), []);
  const { visibleColumns } = useColumn(columns);

  return (
    <div>
      <PageHeader btnText={'Add Certificate'} href={routes?.panel?.deviceManager?.addCertificates} title={'Add Certificate'} />
      <ControlledTable
        variant="modern"
        isLoading={loadingState?.doc?.certificates}
        showLoadingText={true}
        data={reduxCertificates?.doc?.content}
        columns={visibleColumns}
        className={TableClass}
      />
    </div>
  )
}

export default Certificates
