import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../../../../shared/modal-views/use-modal";
import { HeaderCell } from "../../../../Component/ui/table/table";
import Skeleton from "react-loading-skeleton";
import DeletePopover from "../../../../shared/delete-popover";
import { ActionIcon, Text } from "rizzui";
import PencilIcon from "../../../../Constant/Icons/pencil";
import { setOrderApiJson } from "../../../../Store/Action/order/order-action";

export const GetAddedVehicleColumn = (reduxState) => {
    const dispatch = useDispatch()
    const reduxSelect = useSelector(state => state.SearchableSelectReducer)
    const [loadingRows, setLoadingRows] = useState({});
    const { openModal, closeModal } = useModal();

    const handleDelete = (row) => {
        console.log('row', row);
        var filterItems = reduxState?.vehicleIds?.filter(Obj => Obj?.vehicleId?._id !== row?.vehicleId?._id)
        Object.assign(reduxState, { vehicleIds: filterItems })
        dispatch(setOrderApiJson(reduxState))
        // console.log('filterItems', filterItems);
    }

    const renderCell = (value, row, content) => (
        loadingRows[row?.index] ? <Skeleton /> : content
    );

    return [
        {
            title: <HeaderCell title="#" />,
            dataIndex: 'index',
            key: 'index',
            width: 10,
            render: (value, row, index) => renderCell(value, row, <Text>{index + 1 || '---'}</Text>),
        },
        {
            title: <HeaderCell title="_id" />,
            dataIndex: '_id',
            key: '_id',
            width: 10,
            render: (value, row, index) => renderCell(value, row, <Text>{row?.vehicleId?._id || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Vehicle Number" />,
            dataIndex: 'vehicleNumber',
            key: 'vehicleNumber',
            width: 50,
            render: (value, row, index) => renderCell(value, row, <Text>{row?.vehicleId?.vehicleNumber || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Vehicle Type" />,
            dataIndex: 'vehicleType',
            key: 'vehicleType',
            width: 50,
            render: (value, row, index) => renderCell(value, row, <Text>{row?.vehicleId?.vehicleType || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Vehicle Details" />,
            dataIndex: 'grade',
            key: 'grade',
            width: 50,
            render: (value, row, index) => renderCell(value, row, <Text>{row?.vehicleId?.grade || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Product Qty" />,
            dataIndex: 'vehicleMaker',
            key: 'vehicleMaker',
            width: 50,
            render: (value, row, index) => renderCell(value, row, <Text>{row?.vehicleId?.vehicleMaker +'/'+ row?.vehicleId?.vehicleModel +'/'+ row?.vehicleId?.vehicleYear  || '---'}</Text>),
        },
        {
            title: <HeaderCell title="Actions" className="font-extrabold" />,
            dataIndex: 'action',
            key: 'action',
            width: 300,
            render: (_, row, index) => renderCell(null, row, (
                <div className="space-x-2">
                    <DeletePopover
                        title="Delete Product"
                        description="Are you sure you want to delete this product from order?"
                        onDelete={() => handleDelete(row)}
                    />
                </div>
            )),
        },
    ];
};
