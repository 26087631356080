import React, { useEffect, useState } from "react";
import { HitApi } from "../../Store/Action/Api/ApiAction";
import { searchTagHistory } from "../../Constant/Api/Api";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardData } from "../../Store/Action/dashbaord/DashboardAction";
import BarChart from "../../Component/BarChart";
import { GetFullYear, printPreviousDays } from "../../Utils/Utils";
import DummyData from './dummy-data.json';
const ChartView = ({apiJson,api,isSingleBarChart}) => {

    const dashbaordRedux = useSelector(state => state.DashboardReducer);
    const dispatch = useDispatch()

    useEffect(() => {
        if (dashbaordRedux?.doc?.chart?.data === null) {
            fetchTagHistory();
        }
    }, [dashbaordRedux]);

    const fetchTagHistory = () => {
        const today = new Date();
        const sevenDaysAgo = new Date();
        if (dashbaordRedux?.doc?.chart?.type === 'Weekly') {
            sevenDaysAgo.setDate(today.getDate() - 7);
        } else {
            sevenDaysAgo.setDate(today.getDate() - 30);
        }
        const startDate = sevenDaysAgo.getTime();
        const endDate = today.getTime();
        const adjustedEndDate = new Date(today);
        adjustedEndDate.setHours(23, 59, 59, 999);
        const json = apiJson
        json.search.updatedAt = {
            $gte: startDate,
            $lte: endDate // Use the adjusted end date
          }

        HitApi(json, api).then(res => {
            const groupedData = {}
            res?.content?.forEach(item => {
                const date = GetFullYear(item?.updatedAt);
                const rfidTag = String(item.rfidTag).toLowerCase();

                if (!groupedData[date]) {
                    groupedData[date] = { active: [], inactive: [] };
                }
                const targetArray = item.opreationStatus === 'ACTIVE' ? 'active' : 'inactive';
                if (!groupedData[date][targetArray].some(existingItem => existingItem.rfidTag === rfidTag)) {
                    groupedData[date][targetArray].push({ ...item, date, rfidTag });
                }
            });
            const sevenDaysArr = printPreviousDays(dashbaordRedux?.doc?.chart?.type === 'Weekly' ? 7 : 30);
            const newGroupedData = sevenDaysArr.map(date => ({
                [date]: {
                    active: groupedData[date]?.active || [],
                    inactive: groupedData[date]?.inactive || []
                }
            }));
            const finalChartData = [
                { XAxis: newGroupedData.map(obj => Object.keys(obj)[0]) },
                {
                    YAxis: [
                        { ActiveData: newGroupedData.map(group => group[Object.keys(group)[0]].active.length) },
                        { InActiveData: newGroupedData.map(group => group[Object.keys(group)[0]].inactive.length) }
                    ]
                }
            ];
            const oldData = { ...dashbaordRedux?.doc, chart: { type: dashbaordRedux.doc.chart.type, data: finalChartData } };
            dispatch(setDashboardData(oldData));
        });
    };

    const setChartType = (type) => {
        var oldData = dashbaordRedux?.doc
        oldData.chart = { type: type, data: null }
        dispatch(setDashboardData(oldData))
    }

    return (
        <div>
            <BarChart isSingleBarChart={isSingleBarChart} setChartType={setChartType} type={dashbaordRedux?.doc?.chart?.type} ChartData={dashbaordRedux?.doc?.chart?.data} text={'Inventory Data'} />
        </div>
    );
};

export default ChartView;