
export const SET_ORDER_DATA = 'SET_ORDER_DATA'
export const SET_ORDER_SELECTED_PRODUCT = 'SET_ORDER_SELECTED_PRODUCT'
export const SET_ORDER_BUILDING_DATA = 'SET_ORDER_BUILDING_DATA'
export const SET_ORDER_API_JSON = 'SET_ORDER_API_JSON'
export const SET_ORDER_SEARCH_JSON = 'SET_ORDER_SEARCH_JSON'

export const setOrderData = (data) => ({ type: SET_ORDER_DATA, value: data });
export const setOrderSelectedProduct = (data) => ({ type: SET_ORDER_SELECTED_PRODUCT, value: data });
export const setOrderBuildData = (data) => ({ type: SET_ORDER_BUILDING_DATA, value: data });
export const setOrderApiJson = (data) => ({ type: SET_ORDER_API_JSON, value: data });
export const setOrderSearcjJson = (data) => ({ type: SET_ORDER_SEARCH_JSON, value: data });
