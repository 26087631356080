import React, { useEffect, useState } from 'react';
import { RoundedCard } from '../../../../Constant/Classes/Classes';
import cn from '../../../../Utils/class-names';
import { useDispatch, useSelector } from 'react-redux';
import SearchableSelect from '../../../../Component/ui/form/select/SearchableSelect';
import { searchBuilding, searchUser } from '../../../../Constant/Api/Api';
import { getAuthenticatedUser } from '../../../../Storage/Storage';
import { setSearchableSelectSelectedData } from '../../../../Store/Action/common/searcheable-select/searcheable-select-action';
import { setOrderApiJson } from '../../../../Store/Action/order/order-action';
import { _id, inboundText, outboundText } from '../../../../Constant/Common/common-variable';

export const KeysToDelete = [
    { removeCase: 'billTo', removeFromSelect: ['billToName'], removeFromRedux: ['billTo', 'billToName'] },
    { removeCase: 'shipTo', removeFromSelect: ['shipToName'], removeFromRedux: ['shipTo', 'shipToName'] },
    { removeCase: 'saleType', removeFromSelect: ['saleType', 'billToName', 'shipToName'], removeFromRedux: ['billTo', 'billToName', 'shipTo', 'shipToName'] },
];

export const SaleType = [
    { label: 'Internal', value: 'internal' },
    { label: 'External', value: 'external' }
];

export default function BillingAndShipping({ validate, errors }) {
    const dispatch = useDispatch();
    const reduxOrder = useSelector(state => state.OrderReducer);
    const reduxSelect = useSelector(state => state.SearchableSelectReducer);
    const user = getAuthenticatedUser();

    const orderType = reduxOrder?.apiJson?.orderType;
    let dynamicSearch = orderType === outboundText ? { buildingName: { "$ne": reduxOrder?.apiJson?.dispatchFromName }  } : { userIds: { "$in": [user?.userId] } };
    let getFieldName = orderType === outboundText ? 'firstName' : 'buildingName';
    let disabled = orderType === outboundText ? false : true;

    useEffect(() => {

        console.log('reduxOrder', reduxOrder?.apiJson);

    }, [orderType]);

    const handleClear = (name) => {
        let json = reduxOrder?.apiJson;
        const findCase = KeysToDelete?.find(Obj => Obj.removeCase === name);
        const filterItems = reduxSelect?.selected?.filter(Obj => !findCase?.removeFromSelect?.includes(Obj?.name));
        dispatch(setSearchableSelectSelectedData(filterItems));
        findCase?.removeFromRedux?.forEach(ele => delete json[ele]);
    };


    const handleSaleType = (e) => {
        const { value } = e;
        let json = reduxOrder?.apiJson;
        Object.assign(json, { ['saleType']: value });
        dispatch(setOrderApiJson(json));
    };

    const handleChange = (e, name, id) => {
        let json = reduxOrder?.apiJson;
        const { label, value, _id } = e;
        Object.assign(json, {
            ...(id && _id && { [id]: _id }),
            ...(name && label && { [name]: value })
        });
        dispatch(setOrderApiJson(json));
    };


    return (
        <div className={cn(RoundedCard, 'my-5')}>
            <div className='grid grid-cols-4 gap-4 '>
                {reduxOrder?.apiJson?.orderType === outboundText && <div> <SearchableSelect name={'saleType'} validate={validate} error={errors} label={'Select Sale Type'} selectionType={'saleType'} defaultOptions={SaleType} onChange={(e) => handleSaleType(e)} onClear={() => handleClear('saleType')} disabled={false} /> </div>}
                <div>
                    {reduxOrder?.apiJson?.orderType === inboundText ? <SearchableSelect name={'billToName'} label={'Select Bill To'} validate={validate} error={errors} selectionType={'billToName'} api={searchBuilding} getFieldName={'buildingName'} dynamicSearch={dynamicSearch} disabled={disabled} /> : null}
                    {reduxOrder?.apiJson?.orderType === outboundText && reduxOrder?.apiJson?.saleType === 'internal' ? <SearchableSelect name={'billTo'} selectionType={'billToName'} validate={validate} error={errors} label={'Select Bill To'} api={searchBuilding} getFieldName={'buildingName'} dynamicSearch={dynamicSearch} onChange={(e) => handleChange(e, 'billToName', 'billTo')} onClear={() => handleClear('billTo')} disabled={disabled} /> : null}
                    {reduxOrder?.apiJson?.orderType === outboundText && reduxOrder?.apiJson?.saleType === 'external' ? <SearchableSelect name={'billTo'} selectionType={'billToName'} validate={validate} error={errors} label={'Select Bill To'} api={searchUser} getFieldName={'firstName'} dynamicSearch={dynamicSearch} onChange={(e) => handleChange(e, 'billToName', 'billTo')} onClear={() => handleClear('billTo')} disabled={disabled} /> : null}
                </div>
                <div>
                    {reduxOrder?.apiJson?.orderType === inboundText ? <SearchableSelect name={'shipToName'} validate={validate} error={errors} label={'Select Ship To'} selectionType={'shipToName'} api={searchBuilding} getFieldName={'buildingName'} dynamicSearch={dynamicSearch} disabled={disabled} /> : null}
                    {reduxOrder?.apiJson?.orderType === outboundText && reduxOrder?.apiJson?.saleType === 'internal' ? <SearchableSelect name={'shipTo'} selectionType={'shipToName'} validate={validate} error={errors} label={'Select Ship To'} api={searchBuilding} getFieldName={'buildingName'} dynamicSearch={dynamicSearch} onChange={(e) => handleChange(e, 'shipToName', 'shipTo')} onClear={() => handleClear('shipTo')} disabled={disabled} /> : null}
                    {reduxOrder?.apiJson?.orderType === outboundText && reduxOrder?.apiJson?.saleType === 'external' ? <SearchableSelect name={'shipTo'} selectionType={'shipToName'} validate={validate} error={errors} label={'Select Ship To'} api={searchUser} getFieldName={'firstName'} dynamicSearch={dynamicSearch} onChange={(e) => handleChange(e, 'shipToName', 'shipTo')} onClear={() => handleClear('shipTo')} disabled={disabled} /> : null}
                </div>
            </div>
        </div>
    );
}
