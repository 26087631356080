export const ScreenName = {
    user : 'user management master',
    roleAndPermission : "roles and permission master",
    siteMaster : 'site master',
    buildingMaster : 'buildings master',
    generalMaster : 'general master',
    zoneMaster : 'zone master',
    locationMaster :'location master',
    productMaster : 'product master',
    customerMaster : 'customer master',
    supplierMaster :'supplier master',
    readerMaster :'reader master',
    readerReplacementMaster :'reader replacement master',
    inventoryMaster :'inventory master',
    vehicleMaster :'vehicle master',
    configurationMaster :'configuration master',
    outbound :'outbound',
    inbound :'inbound',
    Inventory :'Inventory',
}