import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronDown, ChevronRight, Folder, File, PlusCircle, EllipsisVertical, Plus, Edit, Trash2 } from 'lucide-react';
import { setApiJson, setApiResCommingOrNot, setServiceGlobalVariabls, setServiceMasterJson, setServiceRequestData, setWorkingServiceName, setWorkingServiceRequestName } from './store/Action/ServiceMasterAction';
import { AllApiCallHere } from './store/AllApiCallHere';
import { addRequest, deleteApiService, deleteRequest, searchApiService, updateApiService } from './constants/constant';
import AddMoreService from './addMoreService';
import ApiRequest from './ApiRequest';
import { FullJson } from './FullJson';
import CustomInput from './component/custom-input';
import GlobalVariableForm from './GlobalService/GlobalService';
import ApiMapper from './Mapper';
import { autoRequest } from './utils';
import { Colors } from '../../../Constant/Colors/Color';
import Loader from './component/Loader';

export default function ApiService() {
  const ServiceMasterReducer = useSelector(state => state.ServiceMasterReducer);
  const [openServices, setOpenServices] = useState({});
  const dispatch = useDispatch()
  const [openPopupIndex, setOpenPopupIndex] = useState(null);
  const [addMoreServiceModal, setAddMoreServiceModal] = useState(false);
  const [dataForRequest, setDataForRequest] = useState(false);
  const [bodyScreen, setBodyScreen] = useState('');
  const [requestAddModal, setRequestAddModal] = useState(false);
  const [apiType, setApiType] = useState('Api Controller');
  const [selectedService, setSelectedService] = useState(null)
  const [resArr, setResArr] = useState(null)
  const [globalSendLoader, setGlobalSendLoader] = useState(false)

  useEffect(() => {
    if (ServiceMasterReducer?.doc === null) {
      getApiService()
    }
    document.addEventListener('click', function () {
      setOpenPopupIndex(null)
    });
  }, [ServiceMasterReducer])

  const getApiService = () => {
    var json = {
      page: 1,
      limit: 10,
      search: {

      }
    }
    AllApiCallHere(json, searchApiService).then(res => {
      if (res?.content?.length > 0) {
        dispatch(setServiceMasterJson(res?.content))
      }
    })
  }

  const toggleService = (index) => {
    setOpenServices(prev => ({ ...prev, [index]: !prev[index] }));
  };

  const togglePopup = (index) => {
    setOpenPopupIndex(openPopupIndex === index ? null : index);
  };

  const onClickServiceRequest = (i, index, ele, item) => {
    if (ServiceMasterReducer?.globalVariables === null || ServiceMasterReducer?.workingServiceName === null || ServiceMasterReducer?.workingServiceName !== ele?.serviceName) {
      dispatch(setServiceGlobalVariabls(ele?.globalVariables))
    } else {
      dispatch(setServiceGlobalVariabls(ServiceMasterReducer?.globalVariables))
    }
    dispatch(setServiceRequestData(null))
    setDataForRequest({ serviceIndex: i, requestIndex: index })
    setBodyScreen('Request')
    dispatch(setWorkingServiceName(ele?.serviceName))
    dispatch(setWorkingServiceRequestName(item?.requestName))
    dispatch(setApiResCommingOrNot(false))
  };

  const handleDeleteService = (_id) => {
    var confirm = window.confirm('Are you sure to delete this service')
    if (confirm) {
      var json = {
        _id: _id
      }
      AllApiCallHere(json, deleteApiService).then(res => {
        dispatch(setServiceMasterJson(null))
      })
    }
    setOpenPopupIndex(null);
  };

  const editServiceClick = (object) => {
    var oldJson = ServiceMasterReducer?.apiJson
    oldJson.protocol = object.protocol
    oldJson.serviceName = object.serviceName
    dispatch(setApiJson(oldJson))
    setAddMoreServiceModal(object?.type)
  }

  const handleAddGloabalVariables = (ele) => {
    setBodyScreen('Global')
    setSelectedService(ele)
  }

  const handleAddNewRequest = (data) => {
    var json = FullJson
    json.name = ServiceMasterReducer?.apiJson?.requestName
    json.protocol = ServiceMasterReducer?.apiJson?.protocol
    json.serviceName = data.serviceName
    json.serviceId = data._id
    AllApiCallHere(json, addRequest).then(res => {
      if (res.status === 201) {
        var newJson = {
          requestId: res?.data?._id,
          requestName: res?.data?.name
        }
        if (data?.requests === null) {
          data.requests = [newJson]
        }
        else {
          data?.requests.push(newJson)
        }
        delete data.createdAt
        delete data.updatedAt
        AllApiCallHere(data, updateApiService).then(result => {
          setRequestAddModal(false)
        })
      }
    })
  }

  const onClickSend = (ele, i) => {
    setResArr(null)
    console.log('set gload', i);
    setGlobalSendLoader(i)
    console.log('ele',ele);
    autoRequest(ele, setResArr)
  }

  const requestDelete = (id, i, index) => {
    var json = {
      _id: id
    }
    var confirmation = window.confirm('Are your sure do you want to delete request')
    if (confirmation) {
      AllApiCallHere(json, deleteRequest).then(res => {
        var data = ServiceMasterReducer?.doc?.[i]
        data.requests.splice(index, 1)
        AllApiCallHere(data, updateApiService).then(result => {
          window.location.reload()
        })
      })
    }
  }

  if (resArr !== null) {
    if (globalSendLoader !== false && globalSendLoader !== true && typeof globalSendLoader !== 'string')
      setGlobalSendLoader(globalSendLoader?.toString())
  }



  return (
    <div className="grid grid-cols-12 gap-2 h-screen">
      <div className="col-span-4 bg-gray-100 p-4 overflow-y-auto">
        <div className='flex justify-between bg-white shadow-md items-center p-2 rounded-xl mb-5'>
          <h2 className="text-xl font-semibold text-gray-700">
            Services
          </h2>
          <PlusCircle onClick={() => setAddMoreServiceModal('add')} />
        </div>
        {ServiceMasterReducer?.doc?.map((ele, i) => (
          <div key={i} className="mb-2">
            <div className='flex rounded-xl' style={{ background: ele?.serviceName === ServiceMasterReducer?.workingServiceName ? Colors.THEMEPINK : 'none' }}>
              <div className="flex items-center cursor-pointer p-2 hover:bg-gray-200 rounded justify-between" onClick={() => toggleService(i)}>
                <div className='flex items-center cursor-pointer p-2 hover:bg-gray-200 rounded w-60'>
                  {openServices[i] ? (
                    <ChevronDown className="w-4 h-4 mr-2 text-gray-600" />
                  ) : (
                    <ChevronRight className="w-4 h-4 mr-2 text-gray-600" />
                  )}
                  <Folder className="w-5 h-5 mr-2 " color={ele?.serviceName === ServiceMasterReducer?.workingServiceName ? Colors.LOGINRED : '#000'} />
                  <div className='flex justify-between w-full'>
                    <span className="text-gray-700 font-medium" style={{ color: ele?.serviceName === ServiceMasterReducer?.workingServiceName ? Colors.LOGINRED : '#000' }}>{ele?.serviceName}</span>
                  </div>
                </div>
              </div>
              {
                typeof globalSendLoader !== 'string' && globalSendLoader === i && globalSendLoader !== false ?
                  <div className='p-2 mt-2.5 ml-auto'>
                    <Loader />
                  </div>
                  :
                  resArr !== null && i?.toString() === globalSendLoader?.toString() ?
                    <p onClick={() => onClickSend(ele, i)} className=' ml-auto p-2 self-center underline text-green-500 cursor-pointer'>200</p>
                    :
                    <p style={{ color: Colors.LOGINRED }} onClick={() => onClickSend(ele, i)} className='  ml-auto p-2 self-center underline text-blue-500 hover:text-blue-600 cursor-pointer'>Send</p>
              }

              <div className="relative p-4 hover:text-blue-600 cursor-pointer ml-auto">
                <EllipsisVertical
                  size={20}
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePopup(i);
                  }}
                />
                {openPopupIndex === i && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                    <div className="py-1">
                      <button
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        onClick={() => setRequestAddModal(ele)}
                      >
                        <Plus className="mr-2" size={16} />
                        Add New Request
                      </button>
                      <button
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        onClick={() => editServiceClick({ type: ['edit', ele?._id], serviceName: ele?.serviceName, protocol: ele?.protocol })}
                      >
                        <Edit className="mr-2" size={16} />
                        Edit Service Name
                      </button>
                      <button
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        onClick={() => handleDeleteService(ele?._id)}
                      >
                        <Trash2 className="mr-2" size={16} />
                        Delete Service
                      </button>
                      <button
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        onClick={() => handleAddGloabalVariables(ele)}
                      >
                        <Trash2 className="mr-2" size={16} />
                        View Global Variables
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {openServices[i] && (
              <div className="ml-6 mt-1">
                {ele?.requests?.map((item, index) => (
                  <div key={index} className="p-2 hover:bg-gray-200 rounded cursor-pointer" onClick={() => onClickServiceRequest(i, index, ele, item)}>
                    <div className='w-full flex justify-between items-center'>
                      <div className='flex items-center '>
                        <File color={item?.requestName === ServiceMasterReducer?.workingServiceRequestName ? Colors.LOGINRED : '#000'} className="w-4 h-4 mr-2 text-gray-500" />
                        <span style={{ color: item?.requestName === ServiceMasterReducer?.workingServiceRequestName ? Colors.LOGINRED : '#000', textDecoration: item?.requestName === ServiceMasterReducer?.workingServiceRequestName ? 'underline' : 'none' }} className="text-gray-600 text-sm">{item?.requestName}</span>
                      </div>
                      <div className='flex gap-2'>
                        {resArr !== null && resArr[index] === 'done' && i?.toString() === globalSendLoader?.toString() && <p className='text-base text-green-500 underline -mt-1'>Success</p>}
                        <Trash2 onClick={() => requestDelete(item?.requestId, i, index)} className="mr-2 hover:text-red-500" size={16} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="col-span-8 p-4">
        {
          bodyScreen === 'Global' ?
            <GlobalVariableForm selectedService={selectedService} />
            :
            bodyScreen === 'Request' ?
              <div>
                <div className='flex'>
                  <h3 style={{ color: apiType === 'Api Controller' ? Colors.LOGINRED : '#000', borderBottom: apiType === 'Api Controller' ? '1px solid ' + Colors.LOGINRED : '' }} onClick={() => setApiType('Api Controller')} className={`p-2 px-5 mb-2 cursor-pointer`}>Api Controller</h3>
                  <h3 style={{ color: apiType === 'Mapper' ? Colors.LOGINRED : '#000', borderBottom: apiType === 'Mapper' ? '1px solid ' + Colors.LOGINRED : '' }} onClick={() => setApiType('Mapper')} className={` p-2 px-5 mb-2 cursor-pointer`}>Mapper</h3>
                </div>
                {
                  apiType === 'Api Controller' ?
                    <ApiRequest dataForRequest={dataForRequest} />
                    :
                    <ApiMapper />
                }
              </div>
              :
              ''
        }
      </div>
      {
        addMoreServiceModal === 'add' || addMoreServiceModal?.[0] === 'edit' ?
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <AddMoreService setModal={setAddMoreServiceModal} type={addMoreServiceModal === 'add' ? 'add' : addMoreServiceModal} />
          </div>
          :
          ''
      }

      {
        requestAddModal !== false ?
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-xl w-96">
              <h2 className="text-lg font-semibold mb-4">Add Request</h2>
              <div className='mt-2'>
                <CustomInput name='requestName' placeholder="Enter request name" />
              </div>
              <div className='mt-2'>
                <CustomInput name='protocol' placeholder="Enter protocol" />
              </div>
              <div className="flex justify-end mt-2">
                <button onClick={() => setRequestAddModal(false)} className="mr-2 px-4 py-2 text-gray-600 hover:text-gray-800">
                  Cancel
                </button>
                <button onClick={() => handleAddNewRequest(requestAddModal)} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                  Save
                </button>
              </div>
            </div>
          </div>
          :
          ''
      }
    </div>
  );
}