import { validationSchema } from "../utils/validation-schema";

export const addDeviceSchema = (apiJson) => {
    
    console.log('apiJson', !apiJson?.deviceProfile?.mapperId  || !apiJson?.deviceProfile?.profileId);

    return {
        deviceType: validationSchema?.string('Device Type Field is Required'),
        buildingIds: validationSchema?.array()?.required('Placement Field is Required'),
        make: validationSchema?.string('Make Field is Required'),
        model: validationSchema?.string('Model Field is Required'),
        firmwareVersion: validationSchema?.string('Firmware Version Field is Required'),
        protocol: apiJson?.isCloudConfigurable ? validationSchema?.string('Protocol Field is Required') : validationSchema?.string().optional(),
        secureConnectionType: apiJson?.isSecureConnection ? validationSchema?.array().required('Secure Connection Type Field is Required').refine(arr => arr.length > 0, { message: 'Array cannot be empty' }) : validationSchema?.string().optional(),
        host: validationSchema?.string('Host Field is Required'),
        port: validationSchema?.string('PORT Field is Required'),
        ntpServerHost : validationSchema?.string().optional(),
        ntpServerPort : validationSchema?.string().optional(),
        username: validationSchema?.string('Usernmae Field is Required'),
        password: validationSchema?.string('Password Field is Required'),
        brokerDetailsId: validationSchema?.string('Broker Field is Required'),
        certificateId: validationSchema?.string('Certificate Field is Required'),
        apiService: validationSchema?.array()?.required('Api Service Field is Required'),
        deviceProfile : !apiJson?.deviceProfile?.mapperId ? validationSchema?.string('Mapper Field is Required') : validationSchema?.string().optional(),
        configName : !apiJson?.deviceProfile?.profileId ? validationSchema?.string('Profile Field is Required') : validationSchema?.string().optional(),
    }
};
