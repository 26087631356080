import { SET_BUILDING_DATA, SET_DASHBOARD_DATA, SET_INVENTORY_DATA, SET_LOCATION_DATA, SET_READER_DATA, SET_ZONE_DATA } from "../../Action/dashbaord/DashboardAction";

const initialState = {
    doc: {
        table:null,
        chart: {
            type: "Weekly",
            data: null
        },
        tagHistory: null
    },
    selectedBuilding: null,
    selectedInventory: null,
    selectedReader: null,
    zoneData: null,
    locationData: null,
    timestamp: Date.now()
}

const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BUILDING_DATA:
            return ({ ...state, selectedBuilding: action.value, timestamp: Date.now() })
        case SET_INVENTORY_DATA:
            return ({ ...state, selectedInventory: action.value, timestamp: Date.now() })
        case SET_READER_DATA:
            return ({ ...state, selectedReader: action.value, timestamp: Date.now() })
        case SET_ZONE_DATA:
            return ({ ...state, zoneData: action.value, timestamp: Date.now() })
        case SET_LOCATION_DATA:
            return ({ ...state, locationData: action.value, timestamp: Date.now() })
        case SET_DASHBOARD_DATA:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default DashboardReducer;
