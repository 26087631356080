import React, { useEffect, useState } from 'react'
import { Badge, Button, Select, Text } from 'rizzui'
import cn from '../../../../Utils/class-names'
import { CompileSelectData } from './select-promiss';
import { HitApi } from '../../../../Store/Action/Api/ApiAction';
import { STATUS_CLASSES } from '../../../../Constant/Colors/Color';
import { setSearchableSelectData, setSearchableSelectSelectedData } from '../../../../Store/Action/common/searcheable-select/searcheable-select-action';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import CustomLoading from '../../loading/custom-loading';
import { FaCheck } from 'react-icons/fa';
import CustomButton from '../../buttons/custom-button';

function renderOptionDisplayValue(option) {
  const lowerCaseValue = option?.label != null ? option?.label?.toString()?.toLowerCase() : option?.label;
  const statusClass = STATUS_CLASSES[lowerCaseValue] || STATUS_CLASSES.default;
  return (
    <div className="flex items-center">
      <Badge color={statusClass.badgeColor} renderAsDot />
      <Text className={'ms-2 font-medium capitalize ${statusClass.textColor'}>
        {option?.label}
      </Text>
    </div>
  );
}

function renderDefaultDisplay(option) {
  return (
    <div className="flex items-center">
      <Text className="ms-2 capitalize text-gray-800 transition-colors duration-200 ">
        {option?.label || ''}
      </Text>
    </div>
  );
}



export default function SearchableSelect({ api, name, className, dynamicSearch, limit, getFieldName, type, placeholder, disabled, error, onChange, useCustomDisplay, label, reduxState, defaultOptions, onClear, hide, searchable, selectionType, _id, clearable, validate, actionButton, actionButtonClick, actionButtonloading }) {
  const dispatch = useDispatch()
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const [options, setOptions] = useState(null)
  const [selected, setSelected] = useState(null)
  const [loading, setLoading] = useState(null)

  useEffect(() => {
    if (api && options === null && defaultOptions === undefined) {
      loadData();
    } else {
      if (defaultOptions !== null && defaultOptions !== undefined) {
        setOptions(defaultOptions);
      }
    }
  }, [options, defaultOptions]);

  const loadData = () => {
    if (api) {
      setOptions(null)
      const json = { page: 1, limit: limit || 30, search: dynamicSearch || {} };
      setLoading(true)
      HitApi(json, api).then((result) => {
        if (result?.success !== false) {
          CompileSelectData(result?.content, getFieldName, type).then((CompiledData) => {
            if (CompiledData) {
              setOptions(CompiledData);
              dispatch(setSearchableSelectData(result?.content));
              setLoading(false)
            }
          });
        } else {
          setLoading(false)
        }
      });
    }
  };


  const handleChange = (e) => {
    const { value, _id, label } = e;
    let temp_selected = reduxSelect?.selected ? [...reduxSelect.selected] : [];

    const foundIndex = temp_selected.findIndex((Obj) => Obj?.name === name && Obj?.selectionType === selectionType);

    if (foundIndex !== -1) {
      temp_selected[foundIndex] = { name, label, value, selectionType };
    } else {
      temp_selected = [...temp_selected, { name, label, value, selectionType }];
    }
    dispatch(setSearchableSelectSelectedData([...temp_selected]));
    Object.assign(e, { selectionType: selectionType, name: name })
    if (onChange) onChange(e, temp_selected);
    if (validate) validate([name, value]);
  };

  const handleClear = () => {
    var temp_selected = reduxSelect?.selected || [];
    const existingIndex = temp_selected.findIndex(item => item?.name === name && item?.selectionType === selectionType);

    if (existingIndex !== -1) {
      temp_selected.splice(existingIndex, 1);
      dispatch(setSearchableSelectSelectedData([...temp_selected]));

    }

    if (onClear) { onClear() }
  };

  const GetValue = () => {
    const foundItem = reduxSelect?.selected?.find(Obj => Obj.name === name && Obj?.selectionType === selectionType);
    return foundItem?.label;
  }

  console.log('selectionType', selectionType);

  return (
    <div >
      {!hide && <>
        <div className={cn('font-semibold')}>{label && label}</div>
        <div className={cn('flex z-[99999]', actionButton ? '' : '')}>
          <Select name={name} searchable={searchable || false} clearable={clearable === false ? clearable : clearable === undefined ? true : true || true} onClear={() => { handleClear() }}
            options={options || []}
            placeholder={placeholder ? placeholder : `Select ${label || '...'} `}
            className={cn(className, 'bg-white capitalize', selectionType)}
            selectClassName='h-[2.2rem] text-[13px] tracking-wide'
            dropdownClassName="p-2 gap-1 grid z-[99999] capitalize "
            {...(actionButton && { selectClassName: 'pe-0' })}
            getOptionDisplayValue={(option) =>
              useCustomDisplay ? renderOptionDisplayValue(option) : renderDefaultDisplay(option)
            }
            error={error?.[name]}
            value={GetValue()}
            onChange={handleChange}
            disabled={disabled || false}
            // {...(actionButton && { suffix:<Button></Button> })}
            
          />
          {/* {actionButton && <CustomButton className={'rounded-none'} text={<FaCheck />} type={'submit'} onClick={()=>{actionButtonClick && actionButtonClick()}} isLoading={actionButtonloading} /> } */}
        </div>
        {disabled && (
          <span className='text-red-500 text-xs tracking-wide'>
            This field cannot be edited
          </span>
        )}
      </>}
    </div>
  )
}