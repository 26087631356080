import { useState } from "react";
import useCustomAlertController from "../../Hooks/use-custom-alert copy";
import { HeaderCell } from "../../Component/ui/table";
import TableActions from "../../Component/ui/table/TableActions";
import Skeleton from "react-loading-skeleton";
import { ActionIcon, Badge, Text } from "rizzui";
import { getFormattedDate } from "../../Utils/Utils";
import { useModal } from "../../shared/modal-views/use-modal";
import { EditScreen } from "../../shared/edit-screen";
import ProductList from "./product/product-list";
import SearchableSelect from "../../Component/ui/form/select/SearchableSelect";
import { FaCheck } from "react-icons/fa6";
import { CANCELLED, DISPATCHED, IN_BUILDING, IN_TRANSIT, ORDER_PICKED, ORDER_PICKING } from "../../Constant/Common/common-variable";
import { HitApi } from "../../Store/Action/Api/ApiAction";
import { changeOrderStatus } from "../../Constant/Api/Api";
import { useSelector } from "react-redux";
import CustomButton from "../../Component/ui/buttons/custom-button";
import useDynamicLoading from "../../Hooks/use-dynamic-loading";


export const DefaultStatusOption = [
    { label: 'ACTIVE', value: 'ACTIVE', movementStatus: IN_BUILDING, status: true },
    { label: 'LOADING', value: 'LOADING', movementStatus: IN_BUILDING, status: true },
    { label: 'UNLOADING', value: 'UNLOADING', movementStatus: IN_BUILDING, status: true },
    { label: 'DISPATCH', value: 'DISPATCHED', movementStatus: IN_TRANSIT, status: true },
    { label: 'HOLD', value: 'HOLD', movementStatus: IN_BUILDING, status: true },
    { label: 'CANCEL', value: 'CANCELLED', movementStatus: IN_BUILDING, status: true },
]

export const GetOrderColumn = (ApiHit) => {
    const { openModal, closeModal } = useModal();
    const reduxSelect = useSelector(state => state.SearchableSelectReducer)
    const { showCustomAlert } = useCustomAlertController();
    const [loadingRows, setLoadingRows] = useState({});
    const { loadingState, setDynamicLoading } = useDynamicLoading()
    const [selectedStatus, setSelectedStatus] = useState()

    const handleStatusChange = (e) => {
        const { label, value, ele, _id, movementStatus } = e
        console.log('handleStatusChange', e);
        setSelectedStatus({ operationStatus: value, movementStatus: movementStatus })
    };

    const handleUpdateOrder = (row,) => {

        console.log('row', row);
        var json = {
            orderId: row?._id,
            operationStatus: selectedStatus?.operationStatus,
            movementStatus: selectedStatus?.movementStatus,
            orderStatus: selectedStatus?.operationStatus
        }
        console.log('json = = = ', json);
        setDynamicLoading({ [row?.index]: true })
        HitApi(json, changeOrderStatus).then((result) => {
            showCustomAlert(result)
            setDynamicLoading({ [row?.index]: false })
            setTimeout(() => {
                if (ApiHit) { ApiHit() }
            }, 100);
        })


        console.log('reduxSelect', reduxSelect?.selected);
    }

    const renderCell = (value, row, content) => {
        return loadingRows[row?.index] ? <Skeleton /> : content;
    }

    return [
        {
            title: <HeaderCell title="#" />,
            dataIndex: 'index',
            key: 'index',
            width: 10,
            render: (value, row, index) => renderCell(value, row, <Text>{value || '---'}</Text>),
        },
        // {
        //     title: <HeaderCell title="_id" />,
        //     dataIndex: '_id',
        //     key: '_id',
        //     width: 10,
        //     render: (value, row, index) => renderCell(value, row, <Text>{value || '---'}</Text>),
        // },
        {
            title: <HeaderCell title="Dispatch From" className="font-extrabold" />,
            dataIndex: 'dispatchFromName',
            key: 'dispatchFromName',
            width: 100,
            render: (value, row) => renderCell(value, row, (
                <Text className="font-medium text-gray-700">{value || '---'}</Text>
            )),
        },
        {
            title: <HeaderCell title="Order Creation Date" className="font-extrabold" />,
            dataIndex: 'orderDateTime',
            key: 'orderDateTime',
            width: 100,
            render: (value, row) => renderCell(value, row, (
                <Text className="font-medium text-gray-700">{getFormattedDate(value, ['date', 'month', 'year'], '-', ':', '24', true) || '---'}</Text>
            )),
        },
        {
            title: <HeaderCell title="Expected Arrival Date" className="font-extrabold" />,
            dataIndex: 'expectedArrival',
            key: 'expectedArrival',
            width: 100,
            render: (value, row) => renderCell(value, row, (
                <Text className="font-medium text-gray-700">{getFormattedDate(value, ['date', 'month', 'year'], '-', ':', '24', true) || '---'}</Text>
            )),
        }, {
            title: <HeaderCell title="Batch No." className="font-extrabold" />,
            dataIndex: 'batchNumber',
            key: 'batchNumber',
            width: 100,
            render: (value, row) => renderCell(value, row, (
                <Text className="font-medium text-gray-700">{value || '---'}</Text>
            )),
        },
        {
            title: <HeaderCell title="Order Type" className="font-extrabold" />,
            dataIndex: 'orderType',
            key: 'orderType',
            width: 100,
            render: (value, row) => renderCell(value, row, (
                <Text className="font-medium text-gray-700">{value || '---'}</Text>
            )),
        }, {
            title: <HeaderCell title="Order Status" className="font-extrabold" />,
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            width: 100,
            render: (value, row) => renderCell(value, row, (
                <Text className="font-medium text-gray-700">{value || '---'}</Text>
            )),
        },
        {
            title: <HeaderCell title="Movement Status" className="font-extrabold" />,
            dataIndex: 'movementStatus',
            key: 'movementStatus',
            width: 100,
            render: (value, row) => renderCell(value, row, (
                <Text className="font-medium text-gray-700">{value || '---'}</Text>
            )),
        },
        {
            title: <HeaderCell title="Products" className="font-extrabold" />,
            dataIndex: 'productIds',
            key: 'productIds',
            width: 100,
            render: (value, row) => <div>
                <label className="cursor-pointer" onClick={() => EditScreen(openModal, closeModal, row, 'Product List', ProductList, 800, ApiHit)}>Show Product Details</label>
            </div>,
        },
        {
            title: <HeaderCell title="Actions" className="font-extrabold" />,
            dataIndex: 'action',
            key: 'action',
            width: 300,
            render: (_, row) => {
                let hide = false;
                let statusOptions = [...DefaultStatusOption];
                const statusFilters = {
                    [ORDER_PICKING]: option => option.value !== ORDER_PICKING,
                    // [DISPATCHED]: option => { hide = true; return option.value === DISPATCHED; },
                    // [CANCELLED]: option => { hide = true; return option.value === CANCELLED; },
                };

                if (row?.orderStatus && statusFilters[row.orderStatus]) {
                    statusOptions = statusOptions.filter(statusFilters[row.orderStatus]);
                }

                return <div className="flex items-center">
                    <div className="w-48"><SearchableSelect selectionType={row?.index} defaultOptions={statusOptions} clearable={false} onChange={handleStatusChange} actionButton={true} actionButtonClick={() => handleUpdateOrder(row)}  actionButtonloading={loadingState?.doc?.[row?.index]} hide={hide} /></div>
                    <div className="mt-2">
                        {!hide && <CustomButton text={'Update'} type={'submit'} onClick={() => handleUpdateOrder(row)} />}
                    </div>
                </div>
            },
        },
    ];
};
