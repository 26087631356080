import React, { useState } from 'react'
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { deleteSupplier } from '../../../Constant/Api/Api';
import { HeaderCell } from '../../../Component/ui/table';
import { Text } from 'rizzui';
import Skeleton from 'react-loading-skeleton';
import TableActions from '../../../Component/ui/table/TableActions';
import { EditScreen } from '../../../shared/edit-screen';
import useAlertController from '../../../Hooks/use-alert-controller';
import { ScreenName } from '../../../Constant/Screen/Screen';
import AddSupplierMaster from '../../../Form/master/supplier-master/add-supplier-master';
import { routes } from '../../../config/routes';



export const GetSupllierMasterColumns = (openModal, closeModal, ApiHit) => {
  const { showCustomAlert } = useAlertController();
  const [loadingRows, setLoadingRows] = useState({})


  const handleDelete = async (row) => {
    setLoadingRows((prev) => ({ ...prev, [row.index]: true }));

    const json = { _id: row?._id };
    try {
      const result = await HitApi(json, deleteSupplier);
      if (result?.success !== false) {
        showCustomAlert({
          type: 'success',
          title: 'Success!',
          message: result?.message,
        });
        if (ApiHit) { ApiHit(); }
      } else {
        showCustomAlert({
          type: 'error',
          title: 'Delete Error',
          message: result?.message,
        });
      }
    } catch (err) {

    } finally {
      setLoadingRows((prev) => ({ ...prev, [row.index]: false }));
    }
    
  };
  const renderCell = (value, row, content) => (
    loadingRows[row.index] ? <Skeleton /> : content
  );

  return [
    {
      title: <HeaderCell title="#" />,
      dataIndex: 'index',
      key: 'index',
      width: 10,
      render: (value, row, index) => renderCell(value, row, <Text>{index + 1 || '---'}</Text>),
    },
    {
      title: <HeaderCell title="Supplier Name" className="font-extrabold" />,
      dataIndex: 'supplierName',
      key: 'supplierName',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier Code" className="font-extrabold" />,
      dataIndex: 'supplierCode',
      key: 'supplierCode',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier Group" className="font-extrabold" />,
      dataIndex: 'supplierGroup',
      key: 'supplierGroup',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier Email" className="font-extrabold" />,
      dataIndex: 'supplierContactEmail',
      key: 'supplierContactEmail',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier Type" className="font-extrabold" />,
      dataIndex: 'supplierType',
      key: 'supplierType',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier Add1" className="font-extrabold" />,
      dataIndex: 'supplierAddress1',
      key: 'supplierAddress1',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier Add2" className="font-extrabold" />,
      dataIndex: 'supplierAddress2',
      key: 'supplierAddress2',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier Landmark" className="font-extrabold" />,
      dataIndex: 'supplierLandmark',
      key: 'supplierLandmark',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier Country" className="font-extrabold" />,
      dataIndex: 'supplierCountry',
      key: 'supplierCountry',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier State" className="font-extrabold" />,
      dataIndex: 'supplierState',
      key: 'supplierState',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier City" className="font-extrabold" />,
      dataIndex: 'supplierCity',
      key: 'supplierCity',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier PostCode" className="font-extrabold" />,
      dataIndex: 'supplierPostCode',
      key: 'supplierPostCode',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier Contact" className="font-extrabold" />,
      dataIndex: 'supplierContactPhone',
      key: 'supplierContactPhone',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier Gst" className="font-extrabold" />,
      dataIndex: 'supplierGst',
      key: 'supplierGst',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier PAN" className="font-extrabold" />,
      dataIndex: 'supplierPan',
      key: 'supplierPan',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier TAN" className="font-extrabold" />,
      dataIndex: 'supplierTan',
      key: 'supplierTan',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },
    {
      title: <HeaderCell title="Supplier VAT" className="font-extrabold" />,
      dataIndex: 'supplierVat',
      key: 'supplierVat',
      width: 100,
      render: (value, row) => renderCell(value, row, (
        <Text className="font-medium text-gray-700">{value || '---'}</Text>
      )),
    },

    {
      title: <HeaderCell title="Actions" className="font-extrabold" />,
      dataIndex: 'action',
      key: 'action',
      width: 300,
      render: (_, row) => renderCell(null, row, (
        <TableActions
          screen={ScreenName.supplierMaster}
          row={row}
          onDelete={(rowData) => handleDelete(rowData)}
          checkKeys={[]}
          href={routes?.panel?.master?.editSupplier}
        />
      )),
    },
  ];

}
